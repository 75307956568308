import { Forms } from './forms';

var Security = {

  init: function(){

    // Add IP address
    $('.js-restrict-ip')
      .on('submit', function (event) {
        event.preventDefault();
        Security.createIP($(this));
      })
      .on('reset', function () {
        window.setTimeout(Security.toggleRangeSelector, 200);
      })
      .on('change', '.js-change-ip-type', function () {
        // Switch between IP address and range
        Security.toggleRangeSelector();
      });

    // Delete IP address
    $('.js-ip-restrictions').on('click', '.a-delete', function (event) {
      event.preventDefault();
      var message = $(this).attr('rel');
      if (window.confirm(message)) {
        Security.deleteIP($(this));
      }
    });
  },

  createIP: function (form) {
    var table  = form.parent().find('table'),
        submit = form.find('.form-submit');

    Forms.hideValidationErrors(form);
    submit.addClass('loading');

    $.post(form.attr('action'), form.serialize(), 'html')
      .done(function (response) {
        table.showMe().append(response);
        Forms.resetForm(form);
      })
      .fail(function (response) {
        form.find('fieldset').prepend(response.responseText);
      })
      .always(function () {
        submit.removeClass('loading');
      });
  },

  toggleRangeSelector: function () {
    if ($('.js-change-ip-type').val() === 'address') {
      $('#restrictions-ip-range').hideMe();
    } else {
      $('#restrictions-ip-range').showMe();
    }
  },

  deleteIP: function (button) {
    var table = button.closest('table');

    button.addClass('loading');

    $.post(button.attr('href'), {
      '_method': 'DELETE',
      success: function () {
        button.closest('tr').fadeOut(500, function () {
          if (table.find('tbody > tr:visible').length === 0) {
            table.hideMe();
          }
        });
      },
      complete: function(){
        button.removeClass('loading');
      }
    });
  }
};

$(document).ready(Security.init);
