import { Header } from './header';

export var QuickSearch = {

  init: function (container) {

    var field = container.find('.js-qs-field'),
        list  = container.find('.js-qs-list');

    field
      .keyup(function () {
        QuickSearch.filter($(this), list);
      })
      .keyup()
      .focus();
  },

  filter: function (field, list) {

    var rows   = list.children('li'),
        cache  = rows.map(function () { return $(this).text().toLowerCase(); }),
        term   = $.trim(field.val().toLowerCase()),
        scores = [];

    if (!term) {
      rows.showMe();
    } else {
      rows.hideMe();

      cache.each(function (i) {
        var score = this.score(term);
        if (score > 0) {
          scores.push([score, i]);
        }
      });

      $.each(
        scores.sort(
          function (a, b) {
            return b[0] - a[0];
          }
        ),
        function () {
          $(rows[this[1]]).showMe();
        }
      );
    }

    this.limit(field, list, 10);
  },

  limit: function (field, list, num) {

    var form     = field.parents('form'),
        dropdown = field.parents('.dropdown'),
        results  = list.find('li:visible');


    // Cleanup

    form.submit(function () {
      return false;
    });

    dropdown.find('.total, .empty').remove();


    // Show limited number of results

    if (results.length >= num) {
      results.each(function (i) {
        if (i >= num) {
          $(this).hideMe();
        }
      });
    }


    // Provide shortcut for a single result

    if (results.length === 1) {
      form.submit(function () {
        var singleResult = results.find('a');

        if ($(this).parents('#advanced-search').length) {
          Header.filterByUser(singleResult);
        } else {
          location.href = singleResult.attr('href');
        }
      });
    }


    // Nothing was found

    if (results.length === 0) {
      $('<p />').addClass('empty').text('No matching results.').appendTo(dropdown);
    }


    // Add a total number of results

    if (results.length > num) {
      $('<p />').addClass('total').text('Search for ' + (results.length - num) + ' more.').appendTo(dropdown);
    }

  },

  cleanup: function (container) {

    container.find('.js-qs-field').val('').keyup().blur();

  }
};
