import { Helpers } from './helpers';

export var Deployments = {

  init: function(){

    if ($('#deployments-overview').length) {
      $('.link-deploy a.disabled').removeAttr('href').click(function(event) {
        event.preventDefault();
      });
    }

    $('#deployments-activity')
      .on('click', '[rel="retry-deployment"]', function(event){
        event.preventDefault();
        
        Deployments.retry($(this));
      })
      .on('click', '[rel="cancel-deployment"]', function(event){
        event.preventDefault();
        
        if (confirm('Are you sure?')) Deployments.cancel($(this));
      });
    
    Deployments.start_refreshing();
    Deployments.rootWarning();
    Deployments.sftpPathWarning();

    if ($('.deployment-details').length) {
      $('a.show-revisions').click(function(event){
        event.preventDefault();
        Helpers.addParam('show_all_revs','true');
      });
      $('a.show-files').click(function(event){
        event.preventDefault();
        Helpers.addParam('show_all_files','true');
      });
    }

    if ($('.servers-list').length) {
      $('a.link-delete').click(function(event){
        event.preventDefault();

        if (!confirm('Are you sure you want to delete this server?')) return false;

        $(this).parent().append($('<form></form>', { 'class': 'hidden', method: 'post', action: $(this).attr('href') }).
          append('<input type="hidden" name="_method" value="delete">').
          append($('<input>', { type: 'hidden', name: 'authenticity_token', value: $('meta[name=csrf-token]').attr('content') })));
        $(this).parent().find('form').submit();
      });
    }
  },

  // Warn user that using 'root' user for FTP is bad
  rootWarning: function () {

    var field = $('#release_server_login');

    if (field.length) {
      $(document.createElement('p'))
        .hide()
        .addClass('warning')
        .html('Using the <b>root</b> user for regular tasks is dangerous. Please create a new user specially for deployments.')
        .appendTo(field.parent());

      field.blur(function () {
        if ($(this).val() === 'root') {
          $(this).parent().find('p.warning').slideDown('fast');
        } else {
          $(this).parent().find('p.warning').slideUp('fast');
        }
      });
    }

  },

  sftpPathWarning: function () {
    var field = $('#release_server_remote_path'),
        proto = $('#release_server_protocol');

    if (field.length && (proto.val() === 'sftp' || proto.val() === 'atomic_digital_ocean' || proto.val() === 'atomic')) {
      $(document.createElement('p'))
        .hide()
        .addClass('warning')
        .html('Using <b>/</b> path for SFTP usually points to your server\'s root directory which regular users don\'t have access to. Most likely you need a full path here.')
        .appendTo(field.parent());

      field.blur(function () {
        if ($(this).val() === '/') {
          $(this).parent().find('p.warning').slideDown('fast');
        } else {
          $(this).parent().find('p.warning').slideUp('fast');
        }
      });
    }
  },

  start_refreshing: function() {
    function scheduleRefresh() {
      if ($('#deployments-activity').length) {
        Deployments.activity_timeout = window.setTimeout(function(){
          if ($('.status-waiting, .status-pending, .status-deploying').length) {
            Deployments.refreshActivity(function() {
              if (Deployments.activity_timeout) {
                scheduleRefresh()
              }
            });
          }
        }, 5000);
      }
    }

    scheduleRefresh()
  },

  refreshActivity: function(onComplete) {
    if ($('#deployments-activity').length) {
      Deployments.refresh($('#deployments-activity'), onComplete);
    }
  },

  stop_refreshing: function() {
    if (Deployments.activity_timeout) {
      window.clearTimeout(Deployments.activity_timeout);
      Deployments.activity_timeout = null
    }
  },

  retry: function(el){
    el.parent().addClass('loading');
    
    $.post(el.attr('href'), { _method: 'put' }, function(data){
      el.parents('.release-item').before(data);
      el.parents('.release-item').remove();
    });
  },
  
  cancel: function(el){
    el.parent().addClass('loading');
    
    $.post(el.attr('href'), { _method: 'delete' }, function(data){
      el.parents('.release-item').before(data);
      el.parents('.release-item').remove();
    });
  },
  
  refresh: function(form, onComplete){
    var activity = $('#deployments-activity');

    $.get(form.attr('action'), form.serialize(), function(data){
      activity.html(data).removeClass('loading');
    }, 'html').always(onComplete);
  }
  
}

$(document).ready(Deployments.init);
