var ConfigFiles = {

  init: function () {

    ConfigFiles.add();

  },

  add: function () {
    var trigger = $('.js-add-config-file'),
        orig_select = $('.js-static-file-select');

    orig_select.change(function(event) {
      event.preventDefault();

      var this_select = $(event.target),
          input = this_select.closest('.form-field').find('input[type="text"]');

      if (this_select.val() === "") {
        input.val('');
        input.prop('readonly', true);
      } else {
        input.prop('readonly', false);
      };
    });

    $('.js-static-file-delete').click(function (event) {
      event.preventDefault();

      var this_link = $(event.target),
          blk = this_link.closest('.js-static-file');

      if ($('.js-static-file').length > 1) {
        blk.remove();
      } else {
        blk
          .find('select option:first').prop('selected', true).end()
          .find('input[type="text"]').prop('readonly', true).val('');
      }
    });

    trigger.click(function (event) {
      event.preventDefault();

      var tpl = $('.js-static-file').last(),
      clone = tpl.clone(true);

      clone
        .removeAttr('id')
        .find('select option:first').prop('selected', true).end()
        .find('input[type="text"]').prop('readonly', true).val('').end()
        .insertAfter(tpl);

    });
  }

};

$(document).ready(ConfigFiles.init);
