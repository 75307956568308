export var Forms = {
  
  init: function(){
    $('a.confirm').click(function(event){
      if (!confirm($(this).attr('rel'))) event.preventDefault();
    });

    $('.js-prevent-double-submit').on('submit', function () {
      $(this).find('input[type=submit]').prop('disabled', true);
    });

    $('.js-checkbox-toggle').change(function () {
      var selector = $(this).data('toggleEl'),
          label = $(this).parents('label');

      if ($(this).is(':checked')) {
        label.addClass('active');
        $(selector).showMe();
      } else {
        label.removeClass('active');
        $(selector).hideMe();
      }
    });

    $('.js-select-toggle').change(function () {
      // Hide all options
      var prefix = '#' + $(this).prop('id') + '_';
      $(this).find('option').each(function () {
        $(prefix + $(this).val()).hideMe().prop('disabled', true);
      });

      // Show the selected one
      $(prefix + $(this).val()).showMe().prop('disabled', false);
    });


    // Alt fields

    $('.js-alt-fields').on('focus', function () {
      var fieldset = $(this).closest('.altfields-item'),
          altset   = fieldset.siblings('.altfields-item');

      altset.removeClass('is-selected');
      fieldset.addClass('is-selected').find(':radio').prop('checked', true);
    });


    // URL Check
    $('.form-url input[type="url"]').URLCheck();

    // Show inline form
    $('.js-show-inline-form').on('click', function (event) {
      event.preventDefault();

      var target = $(this).attr('href');

      $(this).addClass('disabled');
      $(target).showMe();
    });

    // Hide inline form
    $('.js-hide-inline-form').on('click', function (event) {
      event.preventDefault();

      var form = $(this).closest('form');

      $('.js-show-inline-form[href="#' + form.attr('id') + '"]').removeClass('disabled');
      form.hideMe();
      Forms.resetForm(form);
    });

    Forms.ownerChange();
    Forms.colorLabelPreview();
    Forms.loading();
    Forms.toggle();
    Forms.cloneServer();
    Forms.creditCardType();
    Forms.integrations();
  },

  hideValidationErrors: function (form) {
    form
      .find('.error-msg').remove().end()
      .find('.form-error').removeClass('form-error');
  },

  resetForm: function (form) {
    form[0].reset();
    Forms.hideValidationErrors(form);
  },

  ownerChange: function(){
    $('#account-owner select').data('owner', $('#account-owner select').val());
    
    $('#account-owner select').change(function(){
      if ($(this).val() != $(this).data('owner')) {
        $('.message').showMe().highlight();
      } else {
        $('.message').hideMe();
      }
    });
  },
  
  colorLabelPreview: function(){  
    $('#label-preview').siblings('select').change(function(){
      // Header label preview
      if ($(this).data('scope') === 'environment') {
        $('.toolbar-title i[class^="label-"]').attr('class', 'label-' + $(this).val());
      } else {
        $('.header-title .color-label b').attr('class', 'label-' + $(this).val());
      }

      // Text label preview
      $('#label-preview.rev-repo').attr('class', 'rev-repo label-' + $(this).val());

      // Color block preview
      $('#label-preview b').attr('class', 'label-' + $(this).val());

      // Environment label
      $('#label-preview i').attr('class', 'label-' + $(this).val());
    });
  },

  loading: function(){
    $('.forms .form-buttons input, .form-buttons.has-loader input').click(function(event){
      event.preventDefault();
      
      $(this).parent().addClass('loading').end().parents('form').submit();
    });
    
    if ($('.loading-indicator').length == 0) return;
    
    var show = false;
    
    $('form').submit(function(event){
      $('.loading-indicator').showMe();
      $(this).hideMe();
    });
  },
  
  toggle: function(){
    $('.form-toggle a').click(function(event){
      event.preventDefault();
      
      if ($(this).parents('.form-row').find('.form-field').length > 0)
        $(this).parents('.form-row').find('.form-field').toggleMe();
      else
        $(this).parents('.form-row').next('.form-row').toggleMe();
      
      $(this).parents('.form-toggle').toggleClass('show');
    });
  },
  
  cloneServer: function(){

    function resetServersList () {
      servers
        .first().showMe()
        .find('option:first').prop('selected', true);
    }

    var repositories = $('.js-clone-repositories'),
        servers      = $('.js-clone-servers');

    if (repositories.length && servers.length) {

      resetServersList();

      repositories.on('change', function () {
        var id = $(this).val();

        // Hide & Disable all
        servers.hideMe().prop('disabled', true);

        if (id !== '') {
          $('#' + id).showMe().prop('disabled', false);
        } else {
          resetServersList();
        }
      });
    }
  },

  creditCardType: function () {
    function getCardType (num) {
      if (/^5[1-5]/.test(num)) {
        return 'mastercard';
      }
      else if (/^4/.test(num)) {
        return 'visa';
      }
      else if (/^3[47]/.test(num)) {
        return 'amex';
      }
      return undefined;
    }

    $('.cc-num').on('keyup blur', function () {
      var type      = getCardType($(this).val().replace(/[ -]/g, '')),
        icon      = $('.cc-type li'),
        className = 'current';

      icon.removeClass(className);

      if (type !== undefined) {
        $('.cc-' + type).addClass(className);
      }
    });
  },

  integrations: function () {

    function normalize() {
      $('.integration-form').hideMe();
      $('.integration-accounts').showMe();
      $('.integration-actions .button').removeClass('disabled');
    }

    $('.integration')
      .on('click', '.js-show-integration', function (event) {
        event.preventDefault();

        if ($(this).hasClass('disabled')) { return; }

        normalize();

        if ($(this).hasClass('button')) {
          $(this).addClass('disabled');
        }
 
        var form = $($(this).prop('rel'));
        form.toggleMe();
        form.find(':input').filter(':first').focus();
      })
      .on('click', '.js-hide-integration', function (event) {
        event.preventDefault();

        normalize();
      });

  }

}

$(document).ready(Forms.init);
