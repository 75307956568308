jQuery.fn.mask = function(mode){
  
  if (this.length == 0) return;

  if (mode == undefined) mode = 'show';

  $(this).each(function(){
    var self = $(this);
    
    if (self.data('mask') == null) self.data('mask', $('<div class="mask"></div>').hide().appendTo($('body')));
      
    var mask = self.data('mask');

    if (mode == 'show') show(); else hide();

    function show(){
      mask.show();
      
      refresh();
    }
    
    function hide(){
      mask.hide();
    }
    
    function refresh(){
      mask.css({
        top      : self.position().top,
        left    : self.position().left,
        width    : self.width(),
        height  : self.height(),
        opacity  : .5
      });
    }
    
    $(window).resize(refresh);
  });  
  
  return this;

};