
var SidebarNavigation = {

  init: function () {
    if (!$('#sidebar-nav').length) return;
    SidebarNavigation.toggleEvents();
    SidebarNavigation.pinOrUnpin();
    SidebarNavigation.filter();
    SidebarNavigation.scrollToHighlighted();
  },

  toggleEvents: function() {
    $('.metanav-sidebar-icon').on("click", function() {
      $('#container').toggleClass("sidebar-nav-visible");
    });
  },

  pinOrUnpin: function() {
    $('#sidebar-pin-button').on("click", function() {

      $(this).toggleClass('pinned');

      $.ajax({
        url: "/users/pin_or_unpin_sidebar_navigation",
        method: "PUT",
        data: { pin: $(this).hasClass('pinned') }
      });
    });
  },

  scrollToHighlighted: function() {
    var current = document.querySelector('.sidebar-nav-item--current');
    if (current) {
      current.scrollIntoView({ behavior: 'smooth' })
    }
  },

  filter: function() {
    $("#sidebar-filter-input").on("input", function(e) {
      var regExp = RegExp(
        $(this)
        .val()
        .trim()
        .split(/\s+/)
        .map(
          function(item) {
            return '(?=[^]*' + item.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') + ')';
          })
        .join(''), 'i');

      $('#sidebar-nav-items li')
        .addClass('hide')
        .filter(function() {
          return $(this)
            .children(':not(ul)')
            .text()
            .match(regExp)
        })
        .find('li')
        .addBack()
        .parentsUntil('#sidebar-nav-items', 'li')
        .addBack()
        .removeClass('hide');
    }).trigger('input');
  },

};

$(document).ready(SidebarNavigation.init);

