var Tooltips = {

  settings: {
    xOffset: 10,
    yOffset: 20
  },

  init: function () {
    if ($('body').width() <= 480) { return; }

    var selector = '[rel=tooltip], a[rel="new-window"]';

    $(document)
      .on('mouseenter', selector, function (event) {
        if ($(this).attr('title') === undefined || $(this).attr('title') === '') { return; }

        var tooltip;

        // Remove title but preserve its content
        $(this)
          .data('title', $(this).attr('title'))
          .attr('data-tooltip', true)
          .removeAttr('title');

        // Create tooltip
        $('.tooltip').remove();
        tooltip = $(document.createElement('div')).addClass('tooltip').text($(this).data('title')).hide().appendTo($('body'));

        // Show tooltip with a small delay
        $(this).data('show-timeout', window.setTimeout(function () {
          Tooltips.show(tooltip, event);
        }, 100));

        // Remove tooltip if parent was removed
        tooltip.data('show-interval', window.setInterval(function () {
          if (!$('[data-tooltip]').length) {
            Tooltips.hide($('.tooltip:visible'));
          }
        }, 500));
      })
      .on('mouseleave', selector, function () {
        if ($(this).data('title') === undefined) { return; }

        // Revert title and remove data attribute
        $(this)
          .attr('title', $(this).data('title'))
          .removeAttr('data-tooltip')
          .removeData('title');

        // Remove tooltip after a small delay
        window.clearTimeout($(this).data('show-timeout'));
        Tooltips.hide($('.tooltip:visible'));
      })
      .on('mousemove',  selector, function (event) {
        Tooltips.update(event, $('.tooltip:visible'));
      });

  },

  show: function (tooltip, event) {
    tooltip.fadeIn(100);
    Tooltips.update(event, tooltip);
  },

  hide: function (tooltip) {
    if (!tooltip.length) { return; }

    tooltip.fadeOut(100, function () {
      window.clearInterval(tooltip.data('show-interval'));
      tooltip.remove();
    });
  },

  update: function (event, tooltip) {
    if (!tooltip.length) { return; }

    var offset = 0;

    if ((event.pageX + Tooltips.settings.yOffset + tooltip.outerWidth() + 30) >= $(window).width()) {
      offset = tooltip.outerWidth() + 30;
    }

    tooltip.css({
      top:  (event.pageY - Tooltips.settings.xOffset) + 'px',
      left: (event.pageX + Tooltips.settings.yOffset - offset) + 'px'
    });
  }

};

$(document).ready(Tooltips.init);