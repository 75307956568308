var Dashboard = {

  init: function () {
    Dashboard.loadUpdatedEnvironments();

    $('.indv-deploy_desc').find('a').on('click', function (event) {
      if ($(this).closest('.indv-deploy--waiting, .indv-deploy--pending, .indv-deploy--skipped').length) {
        event.preventDefault();
      }
    });
  },

  loadUpdatedEnvironments: function () {

    var block = $('.js-load-updated-envs');

    if (!block.length) { return; }

    block.load(block.data('updated-envs-uri'), function () {
      Dashboard.navigateReadyEnvs();
      block.find('.time').timeAgoInWords();
    });
  },

  reloadDeploymentStatus: function (data) {

    var prefix = 'indv-deploy--',
        statuses = ['waiting', 'pending', 'failed', 'skipped'],
        obj = $('#' + data.id),
        badge = obj.find('.indv-deploy_badge');

    if (!obj.length) { return; }

    // Remove current status

    statuses.forEach(function (value, i) {
      obj.removeClass(prefix + value);
    });

    // Apply new status
    obj.addClass(prefix + data.status);
    badge.attr('title', data.title);
  },

  navigateReadyEnvs: function () {

    function slide () {
      container.css('margin-left', (current * -100) + '%');
    }

    function toggleActions () {
      var prev = $('.indv-deploy-nav--prev'),
          next = $('.indv-deploy-nav--next');

      if (current === 0) {
        prev.hideMe();
      } else {
        prev.showMe();
      }

      if (current === (sets.length - 1)) {
        next.hideMe();
      } else {
        next.showMe();
      }

      setCounterIndicator();
    }

    function setCounter () {
      var indicator = counter.find('.slides-counter_indicator');

      counter.empty();

      if (sets.length > 1) {
        for (var i = 0; i < sets.length; i++) {
          counter.append(indicator.showMe().clone());
        }

        setCounterIndicator();
      }
    }

    function setCounterIndicator () {
      var indicators = counter.find('.slides-counter_indicator');

      indicators
        .removeClass('is-current')
        .eq(current).addClass('is-current');
    }


    var container = $('.indv-deploy-sets'),
        sets      = $('.indv-deploy-set'),
        setWidth  = Math.round(100 / sets.length * 100) / 100,
        counter   = $('.slides-counter'),
        current   = 0;

    // Init
    $('.indv-deploy-sets').css('width', (sets.length * 100) + '%');
    $('.indv-deploy-set').css('width', setWidth + '%');
    toggleActions();
    setCounter();

    $(document)
      .on('click', '.js-dashboard-ready-prev', function (event) {
        event.preventDefault();

        if (current > 0) {
          current -= 1;
        } else {
          current = 0;
        }

        slide();
        toggleActions();
      })
      .on('click', '.js-dashboard-ready-next', function (event) {
        event.preventDefault();

        if (current < (sets.length - 1)) {
          current += 1;
        } else {
          current = 0;
        }

        slide();
        toggleActions();
      });
  }
};

window.Dashboard = Dashboard;
$(document).ready(Dashboard.init);
