jQuery.fn.timeInCurrentZone = function () {

  if (this.length === 0) { return; }

  var ordinal = function (num) {
    var ones = num % 10,
        tens = Math.floor(num / 10) % 10,
        suff;

    if (tens === 1) {
      suff = "th";
    } else {
      switch (ones) {
      case 1:
        suff = "st";
        break;
      case 2:
        suff = "nd";
        break;
      case 3:
        suff = "rd";
        break;
      default:
        suff = "th";
      }
    }
    return num + suff;
  };

  $(this).each(function () {
    var time = new Date($(this).attr('datetime')),
        weekday,
        month,
        hours,
        midday,
        minutes;

    switch (time.getDay()) {
    case 0:
      weekday = 'Sunday';
      break;
    case 1:
      weekday = 'Monday';
      break;
    case 2:
      weekday = 'Tuesday';
      break;
    case 3:
      weekday = 'Wednesday';
      break;
    case 4:
      weekday = 'Thursday';
      break;
    case 5:
      weekday = 'Friday';
      break;
    case 6:
      weekday = 'Saturday';
      break;
    default:
      weekday = false;
    }

    switch (time.getMonth()) {
    case 0:
      month = 'January';
      break;
    case 1:
      month = 'February';
      break;
    case 2:
      month = 'March';
      break;
    case 3:
      month = 'April';
      break;
    case 4:
      month = 'May';
      break;
    case 5:
      month = 'June';
      break;
    case 6:
      month = 'July';
      break;
    case 7:
      month = 'August';
      break;
    case 8:
      month = 'September';
      break;
    case 9:
      month = 'October';
      break;
    case 10:
      month = 'November';
      break;
    case 11:
      month = 'December';
      break;
    default:
      month = false;
    }

    if (time.getHours() === 0 || time.getHours() === 12) {
      hours = 12;
    } else if (time.getHours() < 12) {
      hours = time.getHours();
    } else {
      hours = time.getHours() - 12;
    }

    if (time.getHours() < 12) {
      midday = 'AM';
    } else {
      midday = 'PM';
    }

    if (time.getMinutes() < 10) {
      minutes = '0' + time.getMinutes();
    } else {
      minutes = time.getMinutes();
    }

    $(this).text(weekday + ', ' + month + ' ' + ordinal(time.getDate()) + ' at ' + hours + ':' + minutes + ' ' + midday);
  });
};