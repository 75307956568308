var ConnectRepository = {

  init: function () {

    ConnectRepository.changeService();

    $('.js-change-auth-method').change(function() {
      ConnectRepository.changeAuthMethod($(this).val());
    });

    $('.connected-account select').change(function () {
      ConnectRepository.prefillTitle($(this).val());
    });
  },

  changeService: function () {
    if (!$('.repo-connect').length) { return; }

    function showService (current) {
      $('.service-item.selected').removeClass('selected');
      current.parents('.service-item').addClass('selected');

      $('.repo-connect-service').hideMe();
      $('#service-' + current.val()).showMe();
      $('#extra-settings').showMe();

      ConnectRepository.prefillTitle($('#service-' + current.val()).find('.connected-account.selected').find('select').val());
    }

    function showReposForAccount (account) {
      account.parents('.repo-connect-service').find('.connected-account').removeClass('selected');
      account.parents('.connected-account').addClass('selected');

      ConnectRepository.prefillTitle(account.parents('.connected-account.selected').find('select').val());
    }


    // Switch between services

    $('.js-service-radio').change(function () {
      showService($(this));
    });
    showService($('.js-service-radio:checked'));


    // Switch between connected accounts within service

    $('.js-connected-account').change(function () {
      showReposForAccount($(this));
    });
    showReposForAccount($('.js-connected-account:checked'));
  },

  prefillTitle: function (reponame) {
    if (reponame) {
      $('#repo-title').val($('.connected-account.selected option[value="' + reponame + '"]').text().trim());
    }
  },

  changeAuthMethod: function (method) {
    // Change auth method (password vs key)

    $('.js-auth-method').hideMe();
    $('#auth-' + method).showMe();
  }
};

$(document).ready(ConnectRepository.init);
