var connectRepositoryJSON = require('./connect_repository.json');

var Assistant = {
  init: function () {
    var data = connectRepositoryJSON;
    if (data) {
      var steps = data.steps;
      $.each(steps, function(_, step) {
        step.text = $("<p>").addClass("assistant__title").text(step.title).prop("outerHTML") + step.text;
      });
      Assistant.displayAt(steps[data['initial-step']]);
      Assistant.closeViaX();
      Assistant.openOrCloseViaSideIcon(steps);
      Assistant.changeFocus(steps);
      Assistant.highlightUnhighlightSteps(steps);
    }
  },

  displayAt: function(step) {
    $('.assistant-step--focus').removeClass('assistant-step--focus');
    var node = $(step.selector).filter(":visible").addClass("assistant-step--focus");
    if (node.is(":input")) {
      node.focus();
    } else if (!node.find(":focus").length) {
      node.find(":input:first").focus();
    }

    if (!$('.assistant-container').hasClass('assistant-container--closed')) {
      var assistant = $('[data-assistant=' + step.assistant + ']');
      if (!assistant.is(':visible')) {
        $('.assistant-container').hide();
        assistant.show('fast');
      }
      var msg = assistant.find('.assistant__message');
      if (msg.data("message") !== step.text) msg.html(step.text).data("message", step.text);
      $('.assistant-side-icon div').
        removeClass('active').
        filter('[data-assistant-side-icon=' + step.assistant + ']').
        addClass('active');
    }
  },

  updateState: function(open) {
    if ((!$('.assistant-container').hasClass('assistant-container--closed')) == (!open)) {
      $.ajax({
        url: "/users/open_or_close_assistant",
        type: "PUT",
        data: { state: open }
      });
    }
    $('.assistant-container').toggleClass('hide assistant-container--closed', !open);
    if(!open) $('.assistant-side-icon div').removeClass('active');
  },

  closeViaX: function(){
    $('.assistant__close-icon').on('click', function(){
      Assistant.updateState(false);
    });
  },

  openOrCloseViaSideIcon: function(steps) {
    $(".assistant-side-icon div").on('click', function() {
      if ($(this).hasClass('active')) {
        Assistant.updateState(false);
      } else {
        var dataAttr = $(this).data('assistantSideIcon');
        var el, xstep;
        for (var step_name in steps) {
          var step = steps[step_name];
          if (step.assistant === dataAttr && $(step.selector).is(':visible')) {
            if ($(step.selector).hasClass('assistant-step--focus')) {
              el = step;
              break;
            } else if (!xstep) {
              xstep = step;
            }
          }
        }
        Assistant.updateState(true);
        Assistant.displayAt(el || xstep);
      }
    });
  },

  changeFocus: function(steps) {
    $.each(steps, function(_, step) {
      var handler = function(event) {
        event.stopPropagation();
        Assistant.displayAt(step);
      };
      $(step.selector)
        .on("click", function(event) {
          if (!$(event.target).parents().addBack().is(".button")) handler(event);
        })
        .on("focus", function(event) {
          if (event.target.type === "select-one") handler(event);
        })
        .on("keyup", function(event) {
          if (event.keyCode == 9) handler(event);
        });
    });
  },

  highlightUnhighlightSteps: function(steps) {
    $.each(steps, function(_, step) {
      $(step.selector).on('mouseover', function(e) {
        e.stopPropagation();
        $(this).addClass('assistant-step--highlighter');
      }).on('mouseout', function() {
        $(this).removeClass('assistant-step--highlighter');
      });
    });
  },

};

$(Assistant.init);
