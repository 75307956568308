jQuery.fn.autocomplete = function(options){
	
	if (this.length == 0) return;

	var settings = jQuery.extend({
		type: 'local', // or remote
		data: false,
		url: false,
		maxResults: 10,
		offset: 500,
		blankMessage: 'Sorry, nothing found.',
		onTyping: function(){},
		onQuery: function(){},
		onShow: function(){},
		onClear: function(){},
		onSelect: function(data){}
	}, options);

	$(this).each(function(){
		var el 				= $(this);
		var container = $('<div class="autocomplete hide"><p class="hide">' + settings.blankMessage + '</p><ul></ul></div>').appendTo($('body'));
		var busy 			= false;
		var selected 	= 0;
		var timeout;

		el.keyup(function(event){
			if (jQuery.inArray(event.keyCode, [13, 37, 38, 39, 40, 27]) != -1) return;

			el.addClass('querying');

			window.clearTimeout(timeout);

			settings.onTyping();

			timeout = window.setTimeout(function(){
				if (busy) return;

				settings.onQuery();

				query();
			}, settings.offset);

			refresh();
		});

		el.on('keydown.autocomplete', function(event){
			switch(event.keyCode){
				case 38:
					/* Up */

					event.preventDefault();

					selected--;

					if (selected < 0) selected = container.find('a').length - 1;
				break;
				case 40:
					/* Down */

					event.preventDefault();

					selected++;

					if (selected >= container.find('a').length) selected = 0;
				break;
				case 13:
					/* Return */

					event.preventDefault();

					select(container.find('a.selected'));
				break;
				case 27:
					/* Escape */

					settings.onClear();

					el.blur();

					clear();
					hide();
				break;
			}

			container.find('a').removeClass('selected').end().find('a:eq(' + selected + ')').addClass('selected');
		});

		function query(){
			busy = true;

			switch(settings.type){
				case 'local':
					var data = new Array();

					$.each(settings.data, function(index, value){
						if (value.text.search(new RegExp(cleanupKeywords(el.val()), 'i')) != -1) data.push(settings.data[index]); 
					});

					build(data);
				break;
				case 'remote':
					$.ajax({
					  url: settings.url,
					  dataType: 'json',
					  data: { keywords: el.val() },
					  success: function(data){
							build(data);
						}
					});
				break;
			}
		}

		function build(data){
			el.removeClass('querying');
			busy = false;

			clear();

			$.each(data, function(index, value){
				if (index >= settings.maxResults) return;

				$('<li><a href="#" rel="' + value.id + '">' + value.text + '</a></li>').appendTo(container.find('ul'))
			});

			container.find('a').click(function(event){
				event.preventDefault();

				select(this);
			});

			if (data.length == 0) container.find('p').showMe(); else container.find('p').hideMe();

			settings.onShow();

			selected = 0;

			container.find('a:first').addClass('selected');

			show();
		}

		function select(selected){
			settings.onSelect({
				id: $(selected).attr('rel'),
				text: $(selected).text()
			});

			hide();
			clear();
		}

		function show(){
			container.showMe().highlight();
		}

		function hide(){
			container.hideMe();
		}

		function clear(){
			container.find('a').removeClass('selected').end().find('ul').html('');
		}

		function refresh(){
			container.css({
				width	: el.outerWidth(),
				top		: el.offset().top + el.outerHeight(),
				left	: el.offset().left
			});
		}

		function cleanupKeywords(str){
			var specials = new RegExp("[.*+?|()\\[\\]{}\\\\]", 'g');

			return str.replace(specials, "\\$&");
		}
	});
	
	return this;
	
};