import { Helpers } from './helpers';
import { QuickSearch } from './quicksearch';

export var Dropdown = {

  init: function () {

    // Enable focus on div

    $('.dropdown').attr('tabindex', -1);

    // Toggle dropdowns on click

    $('.js-open-dropdown').click(function (event) {
      event.preventDefault();
      event.stopPropagation();

      Dropdown.decide($(this));
    });

    // Toggle Repositories dropdown on Ctrl + R

    Dropdown.shortcuts();
  },

  decide: function (el) {
    var popup  = '#' + el.data('dropdown');

    if ($(popup).is(':visible')) {
      Dropdown.hide($(popup), el);
    } else {
      Dropdown.show($(popup), el, function () { QuickSearch.init($(popup)); });
    }
  },

  show: function (el, target, initSearch) {
    el.showMe().focus();
    target.addClass('is-open');
    Dropdown.prepare(el, target);

    if (el.is(':empty')) {
      Dropdown.load(el, target, initSearch);
    } else {
      initSearch();
    }
  },

  load: function (el, target, initSearch) {
    if (el.data('prepared')) { return; }

    el.data('prepared', true);

    var loadingClass = 'dropdown--loading';

    el.addClass(loadingClass);

    $.getJSON(target.attr('href'), function (data) {
      el.removeClass(loadingClass);

      var dropdownID   = $(target).data('dropdown'),
          listItems,
          placeholder;

      if (dropdownID === 'recent-repositories') {
        placeholder  = 'Repository name';
        listItems    = Dropdown.generateRepositories(data);
      }
      if (dropdownID === 'recent-environments') {
        placeholder  = 'Environment name';
        listItems    = Dropdown.generateEnvironments(data);
      }


      // Append search form

      if (dropdownID === 'recent-environments' && data.length <= 5) {
        // Don't show search form for less than 5 environments
      } else {
        el.append('<form method="get" autocomplete="off"><fieldset class="quick-search"><input type="search" placeholder="' + placeholder + '" class="search-field js-qs-field" /></fieldset></form>');
      }

      // Append UL list

      el.append(listItems.addClass('dropdown-list js-qs-list'));

      // Init QuickSearch

      if (typeof initSearch !== 'undefined') {
        initSearch();
      }
    });
  },

  generateRepositories: function (data) {
    var el = $(document.createElement('ul'));

    $.each(data, function (index, repo) {
      el.append($('<li>')
        .append($('<a>', { href: repo.uri })
          .append($('<span class="color-label">')
            .append($('<b>').addClass('label-' + repo.color)))
          .append(' ')
          .append($('<u>').text(repo.title))));
    });

    return el;
  },

  generateEnvironments: function (data) {
    var el = $(document.createElement('ul'));

    $.each(data, function (index, environment) {
      el.append($('<li>')
        .append($('<a>', { href: environment.uri })
          .append($('<i>').addClass('label-' + environment.color))
          .append(' ')
          .append($('<u>').text(environment.title))))
    });

    return el;
  },

  prepare: function (el, target) {

    // Close on click outside

    el.on('click.insidePopup touchend.insidePopup', function (event) {
      event.stopPropagation();
    });
    $(document).on('click.outsidePopup touchend.outsidePopup', function (event) {
      Dropdown.hide(el, target);
    });

    // Close on Esc

    $(document).on('keyup.hideByEsc', function (event) {
      if (event.keyCode === 27) {
        Dropdown.hide(el, target);
      }
    });

  },

  hide: function (el, target) {
    $(document).off('keyup.hideByEsc click.outsidePopup touchend.outsidePopup');

    el.off('click.insidePopup touchend.insidePopup').hideMe();

    if (typeof target !== 'undefined') {
      target.removeClass('is-open');
    }

    QuickSearch.cleanup(el);
  },

  shortcuts: function () {

    var modifier  = Helpers.getOS() === 'mac' ? 'Ctrl' : 'Alt',
        reposLink = $('.js-open-dropdown[data-dropdown="recent-repositories"]');

    reposLink.attr('title', reposLink.attr('title') + ' (' + modifier + '+r)');

    $(document).on('keydown.openRepositories', null, modifier + '+r', function () {
      Dropdown.decide(reposLink);
    });

  }
};

$(document).ready(Dropdown.init);

$(window).on('load', function () {
  $('.js-open-dropdown').each(function () {
    if ($(this).attr('href') !== '#') {
      Dropdown.load($('#' + $(this).data('dropdown')), $(this));
    }
  });
});
