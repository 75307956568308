var PasswordStrength = {

  previousOutput: '',

  init: function () {

    var password  = $('#strong-password input[type="password"]'),
        confirm   = $('#password-repeat input[type="password"]'),
        output    = $('.password-strength'),
        rubyErrorExists = false;

    if (!password.length || !confirm.length || !output.length) {
      return;
    }

    // Evaluate strong password

    var  Evaluate = {
      setup: function () {
        var self = this;

        this.cancel();
        this.loading();
        this.timeoutID = window.setTimeout(
          function () {
            self.check();
          },
          750
        );
      },

      cancel: function () {
        if (typeof this.timeoutID === 'number') {
          window.clearTimeout(this.timeoutID);
          delete this.timeoutID;
        }
      },

      loading: function () {
        $(output).addClass('loading').html('');
      },

      check: function () {
        var user_data = {};

        this.cancel();

        ['first_name', 'last_name', 'login', 'email'].forEach(function (id) {
          if ($('#user_' + id).length) {
            user_data[id] = $('#user_' + id).val();
          }
        });

        $.post(
          '/password/errors.html',
          { password: $(password).val(), user: user_data },
          function (data) {
            $(output).removeClass('loading').html(data);

            if (password.data('requireStrong')) {
              if ($(output).find('.psw-label-weak').length) {
                PasswordStrength.fieldStatus(password, false);
              } else {
                PasswordStrength.fieldStatus(password, true);
              }
            }
          }
        );
      }
    };


    // Reset confirmation icon and message

    function resetConfirmation() {
      if ($(confirm).val() !== '') {
        PasswordStrength.resetStatus(confirm);
        PasswordStrength.checkConfirmation(password, confirm, output);
      }
    }


    if (password.parents('.form-row').hasClass('form-error') && password.parents('.form-row').find('.error-msg').length) {
      rubyErrorExists = true;
    }


    // Check password strength

    password
      .keypress(function (e) {

        // Disable Control, Command, Tab and arrow keys...

        if (e.metaKey || e.ctrlKey || e.keyCode === 9 || e.keyCode === 37 || e.keyCode === 38 || e.keyCode === 39 || e.keyCode === 40) {

          // ...but enable Control+v and Command+v

          if (e.which !== 118 && e.which !== 86) {
            return;
          }
        }

        resetConfirmation();

        if (rubyErrorExists) {
          $(password).parents('.form-row').removeClass('form-error').find('.error-msg').hide();
          rubyErrorExists = false;
        }

        output.showMe();
        Evaluate.setup();
      })
      .keyup(function (e) {

        resetConfirmation();

        if ($(this).val() === '') {
          output.hideMe();
        }

        // Recheck password when Backspace or Del pressed

        if ($(this).val() !== '' && (e.keyCode === 8 || e.keyCode === 46)) {
          Evaluate.setup();
        }
      });


    // Check confirmed password

    confirm
      .keyup(function () {
        if (password.data('requireStrong') && !password.hasClass('field-ok')) {
          return;
        }

        // During first attempt lets check confirmation only when it's the same length as password

        if (!$(this).hasClass('field-error') && !$(this).hasClass('field-ok') && $(this).val().length < $(password).val().length) {
          return;
        }

        PasswordStrength.checkConfirmation(password, confirm, output);
      })
      .blur(function () {
        if (password.data('requireStrong') && !password.hasClass('field-ok')) {
          return;
        }

        if ($(password).val() !== '') {
          PasswordStrength.checkConfirmation(password, confirm, output);
        }
      });
  },


  // Add status icon to the field

  fieldStatus: function (field, approved) {
    if (approved) {
      field.removeClass('field-error').addClass('field-ok');
    } else {
      field.removeClass('field-ok').addClass('field-error');
    }
  },


  // Remove status icon from the field

  resetStatus: function (field) {
    field.removeClass('field-error field-ok');
    $('.password-confirm').addClass('hide').html('');
  },


  // Check if password confirmation matches entered password

  checkConfirmation: function (password, confirm, output) {

    var confirmOutput  = $('.password-confirm').length ? $('.password-confirm') : output,
      confirmWarning = '<span class="psw-label psw-label-weak">Your password confirmation doesn’t match entered password.</span>';

    // If only one output field provided lets store it's value
    if (confirmOutput === output && confirmOutput.html().toLowerCase() !== confirmWarning.toLowerCase()) {
      this.previousOutput = confirmOutput.html();
    }

    if ($(confirm).val() === $(password).val()) {

      PasswordStrength.fieldStatus(confirm, true);

      if (this.previousOutput !== '') {
        confirmOutput.html(this.previousOutput);
      }
    } else {
      PasswordStrength.fieldStatus(confirm, false);
      confirmOutput.html(confirmWarning).showMe();
    }
  }
};

$(document).ready(PasswordStrength.init);
