export var Curtain = {
  opacity: 0.9,

  init: function () {
    if ($('#curtain').length === 0) {
      $('<div id="curtain"></div>').hide().prependTo($('body'));
    }
  },

  show: function () {
    Curtain.init();
    $('#curtain').css('opacity', this.opacity).show();
  },

  hide: function () {
    $('#curtain').hide();
  }
};
