import { Help } from './help';

export var Helpers = {
  
  init: function(){

    $('.new-window, a[rel="new-window"]').each(function(){
      $(this).attr('target', '_blank');

      if ($(this).attr('title') === undefined) {
        $(this).attr('title', 'Opens a new window.');
      }
    });
    
    $(document).on('click.openChat', 'a[rel="open-chat"]', function(event){
      event.preventDefault();

      if (window.olark) {
        Help.hide();
        olark('api.box.expand');
      }    
    });

    $(document).on('click.print', 'a[rel="print"]', function (event) {
      event.preventDefault();
      window.print();
    });

    $('.notification.email-bounce .action-hide a').click(function(event){
      event.preventDefault();
      
      var self = $(this);
      
      $('.notification.email-bounce').hide();
    
      $.post(self.attr('href'), { _method: self.attr('rel') });
    });
    
    $('.hide-announce a').click(function(event){
      event.preventDefault();
      
      var self = $(this);
      
      $('.announce').hide();
    
      $.post(self.attr('href'), { _method: self.attr('rel') });
    });


    // Toggle elements

    $(document).on('click.toggleEls', '.js-toggle-trigger', function (event) {
      event.preventDefault();

      var selector = $(this).data('toggle-targets');
      Helpers.toggleEls($(this), $(selector));
    });
    $(document).on('change.toggleEls', '.js-toggle-checkbox', function () {
      var selector = $(this).data('toggle-targets');
      Helpers.toggleEls($(this), $(selector));
    });

    Helpers.footerBelowFold();
    Helpers.openInApp();
    Helpers.browserSupport();
    Helpers.upsell();
    Helpers.setupSmoothAnchors();
    Helpers.linksWithSelectFeature();
    
    $('span.time').timeAgoInWords();
  },

  footerBelowFold: function () {
    function setPageHeight() {
      $('.content, #sidebar').css('min-height', $(window).height() - $('.header').height());
      $('.footer').addClass('is-visible');
    }

    if (!$('#specialPage').length) {
      if ($('#container').height() < $(window).height()) {
        setPageHeight();
      }

      $(window)
        .on('resize.hideFooterBelowFold', setPageHeight)
        .trigger('resize.hideFooterBelowFold');
    }
  },

  openInApp: function(){
    if ($('p.open-in-app').length) {

      // Show only on Mac

      if (Helpers.getOS() == 'mac') {
        $('p.open-in-app').showMe();
      }
    }
  },

  browserSupport: function(){
    var regexp = /(msie) ([\w.]+)/,
        isIE   = regexp.exec(navigator.userAgent.toLowerCase()) || [];

    if (isIE[1] && isIE[2] < 9) {
      $('body').prepend('<div class="global-notification"><p>Seriously, you still use IE '+ isIE[2] +'? To help improve your life, we recommend taking a look at <a href="https://www.google.com/chrome">Google Chrome</a> instead.</p></div>');
    }
  },

  upsell: function(){
    $('.notifier .link-hide, .message .link-hide').click(function(event){
      event.preventDefault();
      
      var self = $(this);
      
      self.parents('.notifier').hide();
      self.parents('.blank-slate').hide();
      
      $.post(self.attr('href'), {
        _method: self.attr('rel')
      });
    });
  },

  setupSmoothAnchors: function(){
    $('.smooth-anchor').each(function(){
      var self = $(this);
      self.click(function(event){
        Helpers.smoothScrollingEvent(event, self)
      });
    })
  },

  smoothScrollingEvent: function(event, element){
    var offset = -10;
    event.preventDefault();
    $.smoothScroll({scrollTarget: $(element.prop('hash')), offset: parseInt(offset)});
    $.bbq.pushState('#' + element.prop('hash'));
  },

  linksWithSelectFeature: function(){
    $('.select-on-click, .url-preview-wrap').click(function (event) {
      event.preventDefault();
      $(this).selectText();
    });
  },

  toggleEls: function (trigger, targets) {

    var currentLabel,
      focusTarget;

    // Toggle targets
    targets.each(function (i, el) {
      $(el).toggleMe();
    });

    // Optional class name
    if (trigger.data('toggle-class') !== undefined) {
      trigger.toggleClass(trigger.data('toggle-class'));
    }

    // Alternate text labels
    if (trigger.data('alt-trigger') !== undefined) {
      currentLabel = trigger.text();
      trigger.text(trigger.data('alt-trigger')).data('alt-trigger', currentLabel);
    }

    // Optional focus inside opened element
    if (trigger.data('focus-on-show')) {
      focusTarget = targets.find(':input:not([type="hidden"]):first').focus();

      if (focusTarget.attr('type') !== undefined) {
        if (focusTarget.attr('type').toLowerCase() === 'text') {
          focusTarget.selectRange(focusTarget.val().length);
        }
      }
    }

    // Update scrollbars
    targets.find('.js-scrollable').each(function (i, el) {
      Helpers.initScroll($(el));
    });
    targets.parents('.js-scrollable').each(function (i, el) {
      if (Helpers.hasScrolling($(el))) {
        Helpers.updateScroll($(el));
      } else {
        Helpers.initScroll($(el));
      }
    });
  },

  /* Detect OS */

  getOS: function(){
    var os       = 'mac', // Let’s use Mac as default
        platform = navigator.platform;

    if (platform.search(/iPhone/ig) != -1 || platform.search(/iPod/ig) != -1 || platform.search(/iPad/ig) != -1) {
      os = 'ios';
    }
    if (platform.search(/Mac/ig) != -1) {
      os = 'mac';
    }
    if (platform.search(/Win/ig) != -1) {
      os = 'win';
    }
    if (platform.search(/Linux/ig) != -1 || platform.search(/Unix/ig) != -1) {
      os = 'nix';
    }

    return os;
  },

  /* URL Parameneters */

  convertUrlToArray: function() {
    var params = [];
    var query  = window.location.search.substring(1).split('&');

    if (window.location.search.length) {
      $(query).each(function(i) {
        params[i] = [];
        var pair = query[i].split('=');
        params[i][0] = decodeURIComponent(pair[0]);
        params[i][1] = pair.length > 1 ? decodeURIComponent(pair[1]) : null;
      });
    }

    return params;
  },

  convertArrayToUrl: function(params) {
    $(params).each(function(i) {
      params[i] = params[i].join('=');
    });

    window.location.search = params.join('&');
  },

  getParamValue: function(key) {
    var value;
    var key    = decodeURIComponent(key);
    var params = Helpers.convertUrlToArray();

    $(params).each(function(i) {
      if (key == params[i][0]) {
        value = params[i][1];
      }
    });

    return value;
  },

  isKeyExist: function(key) {
    var params = Helpers.convertUrlToArray();
    var keyExist = false;

    $(params).each(function(i) {
      if (params[i][0] == key) {
        keyExist = true;
      }
    });

    return keyExist;
  },

  addParam: function(key, value) {
    var params = Helpers.convertUrlToArray();

    $(params).each(function(i) {
      if (params[i][0] == key && params[i][1] != value) {
        params[i][1] = value;
      }
    });

    if (!Helpers.isKeyExist(key)) {
      params.push([key,value]);
    }

    Helpers.convertArrayToUrl(params);
  },

  deleteParam: function(key) {
    var params = Helpers.convertUrlToArray();

    $(params).each(function(i) {
      if (params[i][0] == key) {
        params.splice(i, 1);
        Helpers.convertArrayToUrl(params);
      }
    });

  }


}

$(document).ready(Helpers.init);


/* Extensions */

jQuery.fn.extend({
  
  showMe: function(){ 
    return this.removeClass('hide');
  },
  
  hideMe: function(){ 
    return this.addClass('hide');
  },
  
  toggleMe: function(){
    return this.toggleClass('hide');
  },
  
  isHidden: function(){
    if (this.hasClass('hide'))
      return true;
    else
      return false;
  },
  
  highlight: function(){
    return this.css('opacity', 0).animate({ opacity: 1 }, 250);
  },
  
  pulsate: function(){
    return this.animate({ opacity: 0 }, 250).animate({ opacity: 1 }, 250).animate({ opacity: 0 }, 250).animate({ opacity: 1 }, 250);
  },
  
  exists: function(){
    if (this.length == 0)
      return false;
    else
      return true;
  },
  
  disable: function(){
    return this.addClass('loading');
  },
  
  enable: function(){
    return this.removeClass('loading');
  },

  selectText: function(){
    // http://stackoverflow.com/a/987376

    var element = this[0],
      range,
      selection;

    if (element.tagName === 'INPUT') {
      element.select();
    } else if (document.body.createTextRange) {
      range = document.body.createTextRange();
      range.moveToElementText(element);
      range.select();
    } else if (window.getSelection) {
      selection = window.getSelection();
      range = document.createRange();
      range.selectNodeContents(element);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }
});


// forEach fallback for IE 8, based on 5th edition of ECMA-262
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/forEach

if (!Array.prototype.forEach) {
  Array.prototype.forEach = function (fn, scope) {
    'use strict';
    var i, len;
    for (i = 0, len = this.length; i < len; ++i) {
      if (i in this) {
        fn.call(scope, this[i], i, this);
      }
    }
  };
}
