import { Forms } from './forms';

var Tokens = {

  init: function () {
    $('.js-generate-token').on('submit', function (event) {
      event.preventDefault();
      Tokens.generate($(this));
    });

    $(document).on('click', '.js-delete-token', function (event) {
      event.preventDefault();
      Tokens.remove($(this));
    });
  },

  generate: function (form) {
    var submit = form.find('.form-submit');

    Forms.hideValidationErrors(form);
    submit.addClass('loading');

    $.post(form.attr('action'), form.serialize(), 'html')
      .done(function (response) {
        form.after(response);
        form.siblings('.blank').hideMe();
        Forms.resetForm(form);
      })
      .fail(function (response) {
        form.find('fieldset').prepend(response.responseText);
      })
      .always(function () {
        submit.removeClass('loading');
      });
  },

  remove: function (button) {
    if (!window.confirm('Are you sure you want to delete this API token?')) { return; }

    var token = button.closest('.api-token');

    button.addClass('loading');

    $.ajax(button.attr('href'), {type: 'DELETE'})
      .done(function () {
        token.fadeOut(400, function () {
          if (!$('.api-token:visible').length) {
            token.siblings('.blank').showMe();
          }
        });
      })
      .always(function(){
        button.removeClass('loading');
      });
  }

};

$(document).ready(Tokens.init);
