var ServersStatus = {

  init: function () {
     $('.servers-list').on('click', '.js-check-connection', ServersStatus.checkConnection);
  },

  checkConnection: function (event) {
    event.preventDefault();

    var action = $(this),
        server = $(this).closest('.server');

    ServersStatus.resetServer(server, 'updating');

    $.post(action.attr('href'));
  },

  updated: function (data) {

    var server = $('.server[data-server-id="' + data.server_id + '"]');

    if (!server.length) { return; }

    // Clean up & Update content

    ServersStatus.resetServer(server, data.status);


    // Update server status

    if (data.status === 'success') {
      server.addClass('server--connected');

    } else if (data.status === 'failure') {
      server.addClass('server--failed');

    } else if (data.status === 'updating') {
    }
  },

  resetServer: function (server, status) {

    server
      .removeClass('server--connected server--failed')
      .find('[data-server-status]').hideMe().end()
      .find('[data-server-status="' + status + '"]').showMe();

  }

};

window.ServersStatus = ServersStatus;
$(document).ready(ServersStatus.init);
