var FoldableSets = {

  init: function () {
    var speed = 150;

    $(document)
      .on('click.unfold', '.js-unfold-set', function (event) {
        event.preventDefault();

        var set = $($(this).attr('href')),
            setTrigger = $(this);

        setTrigger.hideMe();
        set.slideDown(speed, function () {
          $(this).removeClass('is-hidden');
          // Had to disable this because on edit server page, when non-empty blocks were unfolded on page load,
          // focus was jumping to the last unfolded block scrolling the page to the bottom
          //FoldableSets.focusOnFirst($(this));
        });
      })
      .on('click.fold', '.js-fold-set', function (event) {
        event.preventDefault();

        var set = $(this).closest('.set-fold'),
            setTrigger = $('.set-unfold').filter('[href="#' + set.attr('id') + '"]');

        set.slideUp(speed, function () {
          $(this).addClass('is-hidden');
          setTrigger.showMe();
        });
      })
      .on('change keyup', '.js-watch-changes :input', function () {
        var set = $(this).closest('.set-fold'),
            setTrigger = $('.set-unfold').filter('[href="#' + set.attr('id') + '"]');

        if (FoldableSets.isSetChanged(set)) {
          set.addClass('is-changed');
          setTrigger.addClass('is-changed');
        } else {
          set.removeClass('is-changed');
          setTrigger.removeClass('is-changed');
        }
      });


    // Unfold all changed fieldsets
    $('.set-fold').filter(':hidden').each(function (i, el) {
      var set = $(el),
          setTrigger = $('.js-unfold-set').filter('[href="#' + set.attr('id') + '"]');

      function unfold () {
        if (FoldableSets.isSetChanged(set)) {
          setTrigger.trigger('click.unfold').addClass('is-changed');
          set.addClass('is-changed');
        }
      }

      if (set.find('.ace-editor').length) {
        set.find('.ace-editor input').on('editor:saved', unfold);
      } else {
        unfold();
      }
    });

  },

  focusOnFirst: function (set) {
    // Focus on the first field

    var firstField = set.find(':input:first');

    if (firstField.closest('.ace-editor').length) {
      ace.edit(firstField.closest('.ace-editor').children('pre')[0]).focus();
    } else {
      firstField.focus();
    }
  },

  isSetChanged: function (set) {

    function checkForChanges (def, field) {
      if (def === undefined) {
        if (field.val() !== '') {
          isChanged = true;
        }
      } else {
        if (field.val() !== def) {
          isChanged = true;
        }
      }
    }

    var allFields = set.find(':input'),
        isChanged = false;

    allFields.each(function (i, el) {
      var field = $(el),
          defaultValue = field.data('default');

      // Return default as a string so it can be compared to value
      if (defaultValue !== undefined) {
        defaultValue = defaultValue.toString();
      }

      if (field.is(':checkbox') || field.is(':radio')) {
        if (defaultValue !== 'checked') {
          if (field.is(':checked')) {
            isChanged = true;
          }
        } else {
          if (!field.is(':checked')) {
            isChanged = true;
          }
        }

      } else if (field.find('option').length) {
        // Selected option isn't specified
        if (defaultValue === undefined && field.find('option[selected]').length === 0) {
          field.data('default', field.find('option:first').val());
          defaultValue = field.data('default');
        }

        checkForChanges(defaultValue, field);

      } else if (field.attr('type') === 'hidden') {
        // Skip all hidden inputs except of editor's value
        if (field.closest('.ace-editor').length) {
          checkForChanges(defaultValue, field);
        }

      } else if (!field.hasClass('ace_text-input')) {
        // Skip editor's textarea used for grabbing input
        checkForChanges(defaultValue, field);
      }
    });

    return isChanged;
  }

};

$(document).ready(FoldableSets.init);
