
$(document).ready(function(){
  var form = $('#release-server-form');
  var field = form.find('#release_server_remote_path');
  if (field.length) {
    var has_absolute_remote_path = field.data('isAbsolute');
    var preview = form.find('.remote-path-preview');
    var remote_path_preview = function(){
      var path = field.val();
      preview.empty();
      if (has_absolute_remote_path && path == '') {
        preview.append('&mdash;');
      } else {
        if (!path.match(/^\//)) {
          if (!has_absolute_remote_path)
            preview.append($('<code style="font-weight:bold;">$HOME</code>'));
          path = '/' + path;
        }
        preview.append(document.createTextNode(path + (path.match(/\/$/) ? '' : '/')));
      }
    };
    field.on('input', remote_path_preview);
    remote_path_preview();
  }
});

