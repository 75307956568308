import { hljs } from './vendor/highlight.pack';
import { Deployments } from './deployments';
import { Curtain } from './curtain';

SHOULD_DEBUG = true;
function _debug(obj) {
  if (!SHOULD_DEBUG)
    return;
  if (window.console && window.console.log)
    window.console.log(obj);
}

var TransferLog = {

    init: function(){
        $(document).on('click', '[rel="show-transfer-log"]', function(event){
            event.preventDefault();
            Deployments.stop_refreshing();
            TransferLog.active = true;
            TransferLog.is_preview = false;
            TransferLog.load($(this));
        });
    },

    spinner_refresher: function() {
        if ($('div.transfer-log ul>li.complete').length == $('div.transfer-log ul>li').length) {
            $('div.transfer-log').removeClass('updating');
        } else {
            setTimeout(TransferLog.spinner_refresher, 1000);
        }
    },

    load: function(el){
        Curtain.init();
        Curtain.show();

        var multi_release_id = el.data('releaseId');

        var container = $('<div class="transfer-log loading"></div>').prependTo($('body')).addClass('grow');
        $.get(el.attr('href'), function(data){
            container.html(data).removeClass('loading');
            if(typeof window.startWebSocket == 'function') {
                // function exists, so we can now call it
                startWebSocket();
            }
            container.find('.link-close').click(function(event){
                event.preventDefault();
                TransferLog.hide(container);
            });
                        
            TransferLog.start(container);
            TransferLog.refresh();
            TransferLog.spinner_refresher();
            setTimeout(TransferLog.spinner_refresher, 1000);
        }, 'html');
        TransferLog.autoscrolled_to_bottom = false;
    },
        
    hide: function(el){
        window.startWebSocket = null;
        $('#deployments-activity').addClass('loading');
        Deployments.refreshActivity();
        TransferLog.active = false;
        el.hide();
        Curtain.hide();
        TransferLog.clear_element_caches();
        $('div.transfer-log').empty().remove();
        if(TransferLog.ws) {
            TransferLog.ws.close();
        }
        Deployments.start_refreshing();
    },
        
    start: function(parent){
      parent.find('.tabs a').each(function(i, el){
          $(el).click(function(event){
              event.preventDefault();
              
              parent.find('.tabs li').removeClass('selected');
              parent.find('.actions').hide();
              var $action_container = parent.find('.actions:eq(' + i + ')');
              $action_container.show();
              TransferLog.scrollToBottom($action_container[0]);
              $(this).parent().addClass('selected');
              TransferLog.refresh();
          });
        });
    },
        
    refresh: function(){
        $('.transfer-log .actions').css('height', $('.transfer-log:first').height() - 113);
    },

    // =========================================
    //
    // New functions made by Dave are below
    // this mark in the file. Changes have
    // been made to the above code as well.
    //
    // =========================================

    
    action_tabs: {},
    action_divs: {},
    autoscrolled_to_bottom: false,

    process_changes: function(json_data) {
        var type = json_data['type'];
        var status = json_data['data']['status'];
        var release_id = json_data['data']['release_id'];

        if(json_data['data']['changes']) {
          TransferLog.update_summary(release_id, json_data['data']['changes']);
        }

        switch (type) {
          case 'deployment_state':
            switch (status) {
                case 'new':
                    TransferLog.set_deploy_status(release_id, 'Please wait...');
                    break;
                case 'calculating-changes':
                    TransferLog.set_deploy_status(release_id, 'Calculating changes...');
                    break;
                case 'done-calculating-changes':
                    TransferLog.set_deploy_status(release_id, 'Done calculating changes...');
                    break;
                case 'deploying':
                    TransferLog.set_deploy_status(release_id, 'Deploying...');
                    break;
                case 'deployed':
                    TransferLog.set_deploy_status(release_id, 'Deployed.');
                    TransferLog.set_deployed(release_id);
                    _debug('DEPLOYED ' + release_id);
                    break;
                case 'failed':
                    TransferLog.set_deploy_status(release_id, 'FAILED.');
                    TransferLog.set_failed(release_id);
                    _debug('FAILED ' + release_id);
                    break;
                case 'no-changes':
                    TransferLog.set_deploy_status(release_id, 'Bypassed.');
                    TransferLog.set_bypassed(release_id);
                    break;
                case 'working-copy':
                case 'initialize-missing-working-copy':
                case 'done-initializing-missing-working-copy':
                case 'saving-changes-list':
                case 'done-saving-changes-list':
                    // Nothing here yet
                    break;
                default:
                    // We didn't plan for these
                    _debug('NEW:');
                    _debug(json_data);
                    break;
            }
            break;
        case 'deployment_logs':
            TransferLog.update_file_list(json_data);
            break;
        case 'no_changes':
            TransferLog.set_deploy_status(release_id, 'Bypassed.');
            TransferLog.set_bypassed(release_id);
            break;
        }
    },

    get_action_div_for_release_id: function(release_id) {
        var $action_div = null;
        if (Object.prototype.hasOwnProperty.call(TransferLog.action_divs, release_id))
            $action_div = TransferLog.action_divs[release_id];
        else {
            $action_div = $('#action-' + release_id);
            TransferLog.action_divs[release_id] = $action_div;
        }

        return $action_div;
    },

    get_action_tab_for_release_id: function(release_id) {
        var $action_tab = null;
        if (Object.prototype.hasOwnProperty.call(TransferLog.action_tabs, release_id))
            $action_tab = TransferLog.action_tabs[release_id];
        else {
            $action_tab = $('#tab-' + release_id);
            TransferLog.action_tabs[release_id] = $action_tab;
        }

        return $action_tab;
    },

    clear_element_caches: function() {
        TransferLog.action_tabs = {};
        TransferLog.action_divs = {};
    },

    set_deploy_status: function(release_id, status) {
        var $action_div = TransferLog.get_action_div_for_release_id(release_id);
        $action_div.find('span.status em').text(status).parent().show();
    },

    humanize: function(str) {
        str = str.replace("_", " ");
        return(str.charAt(0).toUpperCase() + str.slice(1));
    },

    update_summary: function(release_id, changes) {
        var $action_div = TransferLog.get_action_div_for_release_id(release_id);
        buf = '<span class="sep">&bull;</span> Total: ' + changes['total'] + '</span>';
        for(name in changes) {
            if(name == 'total') { continue; }
            if(changes[name] > 0) {
                buf = buf + '<span class="sep">&bull;</span> ' + TransferLog.humanize(name) + ': ' + changes[name];
            }
        }

        $action_div.find('p span.loaded').html(buf);
    },

    set_deployed: function(release_id) {
        var $action_div = TransferLog.get_action_div_for_release_id(release_id);
        $action_div.children('p.status-success').show();

        this.autoscroll($action_div);

        var $action_tab = TransferLog.get_action_tab_for_release_id(release_id);
        $action_tab.addClass("complete");
        $action_tab.find('span').remove();
    },

    scrollToBottom: function($action_div) {
        $action_div.scrollTop = $action_div.scrollHeight;
    },

    autoscroll: function ($action_div) {
        var diff = $action_div.scrollHeight - $action_div.scrollTop;
        if (diff < $('.transfer-log:first').height()) {
            this.scrollToBottom($action_div);
        }
    },

    set_failed: function(release_id) {
        var $action_div = TransferLog.get_action_div_for_release_id(release_id);
        $action_div.children('p.status-failed').show();

        this.autoscroll($action_div[0]);

        var $action_tab = TransferLog.get_action_tab_for_release_id(release_id);
        $action_tab.addClass("complete");
        $action_tab.addClass("failed");
        $action_tab.find('span').remove();      
    },

    set_bypassed: function(release_id) {
        var $action_div = TransferLog.get_action_div_for_release_id(release_id);
        $action_div.children('p.status-bypassed').show();

        this.autoscroll($action_div[0]);

        var $action_tab = TransferLog.get_action_tab_for_release_id(release_id);
        $action_tab.addClass("complete");
        $action_tab.addClass("bypassed");
        $action_tab.find('span').remove();              
    },

    update_file_list: function(json_data) {
        var __innerHTML = function(log){
            if(log['action'] === "start") { return ""; }
            var cls = "";
            if(log['state'] == "error") {
                cls = "progress-error";
            } else if(log['action'] == 'execute') {
                cls = "tl-terminal";
            }

            var node = $('<li></li>').addClass(cls).attr('data-percent', log['percentage'] + ' ')
                .append($('<span class="action"></span>').text(log['action']))
                .append(' ');

            if(log['action'] == 'execute' && log['state'] != "error") {
                node.append($('<pre class="terminal"></pre>').text(log['path']));
            } else {
                node.append(document.createTextNode(log['path']));
            }
            return node;
        };

        var release_id = json_data['data']['release_id'];

        var logs = json_data['data']['logs'];
        var $actions_div = TransferLog.get_action_div_for_release_id(release_id);  
        var $actions_ol = $actions_div.find('ol')
        var $actions_tab = TransferLog.get_action_tab_for_release_id(release_id); 

        var initial_scroll = $actions_ol.length > 0

        for(var idx in logs) {
            $actions_ol.append(__innerHTML(logs[idx]));
        }

        if(typeof hljs != 'undefined') {
            $('pre.terminal').each(function (i, e) {
                hljs.highlightBlock(e)
            });
        }

        var percentage = $actions_ol.children().last().data('percent');
        if (percentage) {
            $actions_tab.find('span').text(percentage + '%');
        }

        if (percentage == "100") {
            $actions_tab.addClass("complete");
        } else if (TransferLog.is_preview) {
            TransferLog.set_deployed(release_id);
        }

        if (!TransferLog.autoscrolled_to_bottom && initial_scroll) {
            TransferLog.autoscrolled_to_bottom = true;
            this.scrollToBottom($actions_div[0]);
        } else {
            this.autoscroll($actions_div[0]);
        }
    }
}

window.TransferLog = TransferLog;
$(document).ready(TransferLog.init);
$(window).resize(TransferLog.refresh);
