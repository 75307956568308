export var Revisions = {

  init: function (options) {
    var self = this,
        timeout;

    self.settings = $.extend({
      url: '',
      disabled: '',
      disabledBranch: '',
      disabledTag: '',
      onLoad: function () {},
      onClick: function () {}
    }, options);

    window.loading = false;
  },

  load: function (hud, onClick) {
    if (window.loading) {
      return;
    }

    var self = this,
        url;

    if (self.isGlobal()) {
      url = self.settings.url;
    } else {
      url = hud.data('url');
    }

    self.set(hud, 'container', []);
    self.set(hud, 'backup', []);

    if (onClick != undefined) {
      this.settings.onClick = onClick;
    }

    if (self.get(hud, 'container').length === 0) {
      window.loading = true;

      hud.find('.revisions').addClass('loading');

      $.getJSON(url, function (data) {
        self.set(hud, 'container', data);
        self.set(hud, 'backup', data);

        window.loading = false;

        Revisions.insert(hud);
      });

    } else {
      Revisions.insert(hud);
    }

    hud.find('.search .action-search').off('click').click(function (event) {
      event.preventDefault();

      Revisions.search(hud, url);
    });

    hud.find('.search input').keydown(function (event) {
      if (event.keyCode !== 13) {
        return;
      }
      Revisions.search(hud, url);
    });

    hud.find('.search .action-clear').off('click').click(function (event) {
      event.preventDefault();

      self.set(hud, 'container', self.get(hud, 'backup'));

      $(this).hideMe();

      Revisions.insert(hud);
      Revisions.reset(hud);
    });

    hud.find('.action-refresh').off('click').click(function (event) {
      event.preventDefault();

      hud.find('.revisions').addClass('loading').jScrollPane();

      $.getJSON(url, function (data) {
        self.set(hud, 'container', data);
        self.set(hud, 'backup', data);

        Revisions.insert(hud);
      });
    });
  },

  insert: function (hud) {
    var self = this;

    if (hud.data('status') === 'hidden') {
      return;
    }

    if (!self.get(hud, 'container').length) {
      hud.find('.revisions').removeClass('loading').find('ul:last').html('<li class="empty">Your search didn’t match any results.</li>');
      return;
    }

    hud.find('.revisions').removeClass('loading').find('ul:last').empty().append(Revisions.generateHTML(self.get(hud, 'container'))).find('a').off('click').click(function (event) {
      event.preventDefault();
      self.settings.onClick($(this));
      $(document).click(); // close HUD correctly
      return false;
    });

    this.settings.onLoad();

    self.refresh(hud);
  },

  disable: function (hud) {

    var settings = hud.data('settings'),
        disabledValues;

    if (settings.kind === 'revision') {
      disabledValues = this.settings.disabled;
    }
    if (settings.kind === 'branch') {
      disabledValues = this.settings.disabledBranch;
    }
    if (settings.kind === 'tag') {
      disabledValues = this.settings.disabledTag;
    }

    $.each(
      disabledValues.split(';'),
      function (i, value) {
        hud.find('a[rel="' + value + '"]').parent().addClass('selected');
      }
    );
  },

  search: function (hud, url) {
    var self = this;

    hud.find('.revisions').addClass('loading').end().find('.action-clear').showMe();

    $.get(url, { keywords: hud.find('.search input').val() }, function (data) {
      self.set(hud, 'container', data);

      Revisions.insert(hud);
    }, 'json');
  },

  refresh: function (hud) {
    hud.find('.revisions').jScrollPane();

    this.disable(hud);
  },

  reset: function (hud) {
    window.setTimeout(function () {
      hud.find('.search input').val('');
    }, 250);
  },

  setDisabled: function (revisions) {
    this.settings.disabled = revisions;
  },

  generateHTML: function (data) {
    return $.map(data, function (el, i) {
      // Revisions
      var link = $('<a>', { href: '#', rel: el.revision }).append($('<strong>').text(el.short_revision));
      if (el.tag !== undefined)
        link.append([' ', $('<sup class="tagged">').text(el.tag)]);
      return $('<li>').append(link.append(document.createTextNode(' ' + el.msg)));
    });
  },

  set: function (hud, type, data) {
    if (this.isGlobal()) {
      if (type === 'container') {
        window.revisions = data;
      } else {
        window.backup = data;
      }
    } else {
      if (type === 'container') {
        hud.data('revisions', data);
      } else {
        hud.data('backup', data);
      }
    }
  },

  get: function (hud, type) {
    if (this.isGlobal()) {
      if (type === 'container') {
        return window.revisions;
      } else {
        return window.backup;
      }
    } else {
      if (type === 'container') {
        return hud.data('revisions');
      } else {
        return hud.data('backup');
      }
    }
  },

  isGlobal: function () {
    if (this.settings.url === ':href') {
      return false;
    } else {
      return true;
    }
  }
};

window.Revisions = Revisions;
