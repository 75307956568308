var ManualDeployment = {

  init: function () {

    if (!$('#manual-deployment').length) { return; }

    // Refresh revisions list

    $('.js-reload-commits').click(function (event) {
      event.preventDefault();
      ManualDeployment.updateRevisions($(this), $(this).attr('href'));
    });


    // Review & Deploy

    ManualDeployment.loadData();
  },

  updateRevisions: function (refreshButton, srcURL) {
    refreshButton.addClass('loading');
    $.get(srcURL);
  },

  loadData: function () {
    if (!$('.js-review-deployment').length) { return; }

    var container = $('.js-review-deployment'),
        loading = $('.deploy-loading'),
        form = container.closest('form'),
        uri = container.data('review-deploy-uri');

    loading.showMe();
    container.empty();

    $.get(uri, form.serialize(), function (data) {
      loading.hideMe();
      container.html(data);
    });

  }
};

$(document).ready(ManualDeployment.init);
