import { Curtain } from './curtain';

var CreateServer = {

  init: function(){
    Curtain.init();
    CreateServer.environment();
    CreateServer.passwordOrKey();
    CreateServer.setPubkeyValue();
    CreateServer.initializeForm();
  },

  environment: function(){
    $('#create-new-environment, #choose-existing-environment').click(function(event){
      event.preventDefault();

      $('#existing-environment, #new-environment').toggleMe();
    });

    $('#choose-environment .form-buttons input').click(function(event){
      event.preventDefault();

      if ($('#new-environment').isHidden()) {
        window.location.href = '?environment_id=' + $('#existing-environment select').val() +
          '&server_type=' + $('#server_type_field').val();
        return false;
      } else {
        $('#choose-environment').submit();
      }
    });
  },

  passwordOrKey: function(){
    if ($('#password-or-key').length == 0) return;

    $('#password-or-key input[type="checkbox"]').click(function(){
      if ($(this).is(':checked')) {
        $('#password-or-key').find('input[type="password"]').prop('disabled', true);
      }
      else {
        $('#password-or-key').find('input[type="password"]').prop('disabled', false);
      }
    });
  },

  initializeForm: function(){
    var connection_test_uri = $('#release-server-form').data('connectionTestUri');
    if(!connection_test_uri) return;

    var timer, xhr; // note: these are shared between the connection test and file system browser
    var cancelActiveRequest = function(){
      if(xhr){
        var old_xhr = xhr;
        clearInterval(timer);
        timer = xhr = null;
        old_xhr.abort();
      };
    };

    (function(){
      var state, connect_tested, write_tested;
      var state_idx = function(st){
        return [
          'start',
          'connect_testing',
          'connect_success',
          'write_testing',
          'write_success'
        ].indexOf(st);
      };
      var state_le = function(st){ return state_idx(state) <= state_idx(st); };
      var state_ge = function(st){ return state_idx(state) >= state_idx(st); };
      var change_state = function(new_state){
        var le = state_le, ge = state_ge;
        cancelActiveRequest();
        if(state != null){
          $('.form-row').removeClass('form-error').find('.error-msg').remove();
          $('div.message.notice, div.message.error').remove();
        }
        state = new_state;
        $('#release_server_remote_addr, #release_server_port, #release_server_login, #release_server_password').prop('readonly', ge('connect_testing'));
        $('#release_server_authenticate_by_key').prop('disabled', ge('connect_testing'));
        $('#connection_test_connect_button').text(le('start') ? 'Connect' : (le('connect_testing') ? 'Cancel' : 'Disconnect'));
        $('#connection_test_connect_in_progress_msg').toggleClass('hide', state != 'connect_testing');
        $('#connection_test_connect_success_msg').toggleClass('hide', !(connect_tested && ge('connect_success')));
        $('#release_server_remote_path').prop('readonly', ge('write_testing'));
        $('#file-system-browser-open-button').prop('disabled', state != 'connect_success');
        $('#connection_test_write_button').
          prop('disabled', !ge('connect_success')).
          text(!ge('write_testing') ? 'Write test' : (state == 'write_testing' ? 'Cancel' : 'Edit path'));
        $('#connection_test_write_success_msg').toggleClass('hide', !(write_tested && ge('write_success')));
        $('#connection_test_before_save_msg').toggleClass('hide', ge('write_success'));
        $('#release-server-form :submit').prop('disabled', !ge('write_success'));
      };
      change_state($('#release-server-form').data('connectionParamsLocked') ? 'write_success' : 'start');
      var perform_test = function(triggering_button, test, testing_state, success_state, failure_state, failure_message){
        change_state(testing_state);
        var show_error = function(msg){
          $(triggering_button).closest('.form-field').append($('<p class="error-msg"></p>').text(msg));
        };
        var show_request_failure = function(){
          change_state(failure_state);
          show_error('Failed to initiate test. Please check your internet connection or try again later!');
        };
        var countdown = 30;
        var handler = function(){
          if(countdown) $('#connection_test_connect_countdown').text(countdown--);
            else show_request_failure();
        };
        handler();
        timer = setInterval(handler, 1000);
        xhr = $.ajax({
          url: connection_test_uri + '?test=' + test,
          method: 'POST',
          data: $('#release-server-form').find(':input[name!=_method]').serialize(),
          success: function(resp, _, this_xhr){
            if(this_xhr !== xhr) return;
            if($.isEmptyObject(resp)){
              change_state(success_state);
            }else{
              change_state($.grep(Object.keys(resp), function(k){ return !['base', 'remote_path'].includes(k) }).length ? 'start' : failure_state);
              show_error(failure_message + ' Please check the error messages above!');
              $.each(resp, function(attr, msg){
                if(attr == "base"){
                  $('#release-server-form').prepend("<div class='message error'><p>" + msg + "</p></div>");
                }else{
                  var field = $('#release_server_' + attr).closest(".form-field").append(msg);
                  if (attr != "authenticate_by_key") field.closest(".form-row").addClass("form-error");
                }
              });
            }
          },
          error: function(this_xhr){
            if(this_xhr === xhr) show_request_failure();
          }
        });
      };
      $('#connection_test_connect_button').click(function(e){
        if(state_le('start')){
          connect_tested = true;
          perform_test(this, 'connect', 'connect_testing', 'connect_success', 'start', 'An error has occurred while trying to establish a connection to your server.');
        }else{
          change_state('start');
        };
      });
      $('#connection_test_write_button').click(function(e){
        if(state_ge('write_testing')){
          change_state('connect_success');
        }else{
          write_tested = true;
          perform_test(this, 'write', 'write_testing', 'write_success', 'connect_success', 'An error has occurred while performing the write test.');
        }
      });
    })();

    (function(){
      var startPathWasRelative, currentPath, reloadPath;
      var fetchDirListing = function(remotePath){
        cancelActiveRequest();
        var updateStatus = function(isError, msg){
          $('#fsb-status-line').text(msg).toggleClass('validation--error', isError);
        };
        updateStatus(false, 'Fetching directory ...');
        onLoadFailed = function(){
          cancelActiveRequest();
          updateStatus(true, "Failed to initiate directory fetch. Please check your internet connection or try again later!");
        };
        timer = setTimeout(onLoadFailed, 30000);
        xhr = $.ajax({
          url: $('#release-server-form').data('dirListingUri'),
          method: "POST",
          data: $('#release-server-form').find(':input[name!=_method]').serializeArray().concat([{ name: 'release_server[remote_path]', value: remotePath }]),
          success: function(resp, _, thisXhr){
            if(thisXhr !== xhr) return;
            cancelActiveRequest();
            if(resp.error){
              updateStatus(true, resp.error)
            } else {
              updateStatus(false, 'Directory fetch complete.');
              var currentPathRelative = startPathWasRelative && resp.path_relative || !resp.path_absolute;
              currentPath = (currentPathRelative ? '' : '/') + (currentPathRelative ? resp.path_relative : resp.path_absolute).join('/');
              var showControlChars = function(s){
                return s.replace(
                  /[\x00-\x1f\x7f]/g,
                  function(x){
                    var c = x.charCodeAt(0);
                    return (c == 0x7f) ? "\u2421" : String.fromCharCode(0x2400 + c);
                  }
                );
              };
              var path = resp.path_absolute || resp.path_relative;
              var pathAbsPrefix = resp.path_absolute ? '/' : '';
              reloadPath = pathAbsPrefix + path.join('/');
              $('#fsb-breadcrumb-navigation').empty().append(
                resp.path_absolute ? '' : '<code style="font-weight:bold;">$HOME</code>',
                '/',
                $.map(path, function(x, i){
                  return [
                    $('<button dir="auto">').text(showControlChars(x)).click(function(){
                      fetchDirListing(pathAbsPrefix + path.slice(0, i + 1).join('/'));
                    }),
                    '/'
                  ];
                })
              );
              var entriesToDom = function(entries, klass, selectable){
                return $.map(entries, function(e){
                  var button = $('<button>', { 'class': klass }).append($('<bdi>').text(showControlChars(e.n)));
                  if(e.l){
                    button.append(
                      ' ',
                      $('<span class="symlink">&#x2192; </span>').
                        append($.map(e.l.split('/'), function(x){ return [$('<bdi>').text(showControlChars(x)), '/']; }).slice(0, -1))
                    );
                  }
                  if(selectable && !e.i) button.click(function(){ fetchDirListing(pathAbsPrefix + path.concat([e.n]).join('/')); });
                    else button.prop('disabled', true);
                  return $('<li>').append(button);
                });
              };
              $('#fsb-dir-listing').empty().append(
                entriesToDom(resp.dirs, 'dir', true),
                entriesToDom(resp.other, 'file')
              );
              $('#fsb-reload-button, #fsb-accept-button').prop('disabled', false);
            };
          },
          error: function(thisXhr){
            if(thisXhr === xhr) onLoadFailed();
          }
        });
      };
      var closeFileSystemBrowser = function(){
        $('#file-system-browser').addClass('hide');
        Curtain.hide();
        $(document).off('keydown.fileSystemBrowser');
        cancelActiveRequest();
      };
      $('#file-system-browser-open-button').click(function(){
        $('#fsb-breadcrumb-navigation').empty().append('&mdash;');
        $('#fsb-reload-button, #fsb-accept-button').prop('disabled', true);
        $('#fsb-dir-listing').empty();
        $(document).on('keydown.fileSystemBrowser', function(e){
          if(e.key === "Escape") closeFileSystemBrowser();
        });
        var path = $('#release_server_remote_path').val();
        startPathWasRelative = !path.match(/^\//);
        fetchDirListing(path);
        Curtain.show();
        $('#file-system-browser').removeClass('hide');
        $('#fsb-close-link').focus();
      });
      $('#fsb-close-link').click(closeFileSystemBrowser);
      $('#fsb-accept-button').click(function(){
        $('#release_server_remote_path').val(currentPath);
        closeFileSystemBrowser();
      });
      $('#fsb-reload-button').click(function(){
        fetchDirListing(reloadPath);
      });
      $('#fsb-root-button').click(function(){
        fetchDirListing('/');
      });
      $('#fsb-home-button').click(function(){
        fetchDirListing('');
      });
    })();
  },

  setPubkeyValue: function(){
    $("#release_server_authenticate_by_key").click(function(){
      $('input[name="release_server[authenticate_by_key]"][type=hidden]').val(this.checked);
    });
  }
};

$(document).ready(CreateServer.init);

