import { Revisions } from './revisions';

$(function(){
    
  $('.js-reset-commit').click(function(event){
    event.preventDefault();
    
    $(this)
      .hideMe()
      .parent()
        .find('.select-revision')
          .text('None')
          .addClass('empty')
          .next('input').val('');
  });

  $('.js-set-commit').each(function(){
    var self = $(this);
    
    self.hud({
      type: 'local',
      size: 'large',
      onShow: function(hud){        
        Revisions.load(hud, function(el){
          if (el.parent().hasClass('selected')) return;

          self.html(el.html()).removeClass('empty').next('a').showMe();
          self.nextAll('input').val(el.attr('rel'));

          $('.revisions ul li.selected').removeClass('selected');
          $('.revisions').find('ul li a[rel="' + el.attr('rel') + '"]').parent().addClass('selected');
        });

        Revisions.setDisabled(self.text());
      },
      onHide: function(hud){
        Revisions.reset(hud);
      }
    });
  });

});
