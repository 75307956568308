var Permissions = {

  init: function () {

    var selectors = $('.permissions tr:not(.action) select');


    // Apply to all

    $('.permissions .action select').each(function (i, el) {
      var table     = $(el).parents('.permissions'),
          dropdowns = table.find('tr:not(.action)').find('select:eq(' + i + ')');

      $(el).on('change.changeAll', function () {
        if ($(el).prop('selectedIndex') === 0) { return; }

        // Update every visible dropdown. -1 because first option is "Apply to all"
        dropdowns.each(function () {
          if ($(this).parents('.permission').hasClass('hide')) { return; }

          $(this).prop('selectedIndex', $(el).prop('selectedIndex') - 1);
          Permissions.update($(this));
        });

        selectors.trigger('change.deployAccess');

        // Reset dropdown to "Apply to all" to avoid confusion when some children changed later
        $(el).prop('selectedIndex', 0);

        Permissions.toggleApplyToAll();
      });
    });


    // Apply fancy dropdowns design

    $('.permissions')
      .find('select').before($(document.createElement('span'))).end()
      .find('label').addClass('permission').each(function () {
        Permissions.update($(this).find('select'));
      });

    $(document).on('change.update', '.permissions label select', function () {
      Permissions.update($(this));
    });


    // Mark every dropdown with a class based on column

    $('.permissions tbody tr').each(function () {
      $(this).find('.permission').each(function (i, el) {
        $(this).addClass('type-' + i);
      });
    });


    // Disable deployments when user has no access to repository

    selectors
      .on('change.deployAccess', function () {
        var current = $(this).find('option:selected').text(),
            deploy  = $(this).parents('td').next('td').find('.permission'),
            select  = deploy.find('select');

        if (current.search(/no\ access/i) !== -1) {
          deploy.hideMe();

          // Reset deployment setting
          select.prop('selectedIndex', 0);
          Permissions.update(select);
        } else {
          deploy.showMe();
        }

        Permissions.toggleApplyToAll();
      })
      .trigger('change.deployAccess');


    // Change permissions

    function addScrollToPermissions() {
      if ($('.permissions-wrapper').height() < $('.permissions').height()) {
        $('.permissions-box').addClass('permissions-box--fading');
        $('.permissions-wrapper').jScrollPane();
      }
    }

    if ($('.permissions-box:visible')) {
      addScrollToPermissions();
    }

    $('#user-permissions input[type="radio"]').change(function () {
      var selected = $(this).parents('#user-permissions').find('input[type="radio"]:checked').val();

      if (selected === 'true') {
        $('.permissions-box').hideMe();
      }
      if (selected === 'false') {
        $('.permissions-box').showMe();
        addScrollToPermissions();
      }
    }).change();


    // Grant administrator rights

    $('#admin-rights input[type="checkbox"]').change(function () {
      var permissionControls = $('.permissions, .permissions-box, #no-repositories');

      if ($(this).is(':checked')) {
        permissionControls.hideMe();
      } else {
        permissionControls.showMe();
      }
    });
  },

  toggleApplyToAll: function () {
    $('.permissions').each(function () {
      var selectors = $(this).find('tr:not(.action) .permission:not(.hide)');

      $(this).find('.action .permission').each(function (i, el) {
        if (selectors.filter('.type-' + i).length === 0) {
          $(el).hideMe();
        } else {
          $(el).showMe();
        }
      });
    });
  },

  update: function (el) {
    var option = el.find('option:selected').text(),
        label  = el.parent(),
        all    = 'apply no-access partial-access full-access';

    // First, set some meaningul default
    label.removeClass(all).addClass('permission partial-access').find('span').text(option);

    // Then apply appropriate styles
    if (option.search(/apply\ to\ all/i) !== -1) { label.removeClass(all).addClass('apply'); }
    if (option.search(/no\ access/i)     !== -1) { label.removeClass(all).addClass('no-access'); }
    if (option.search(/view activity/i)  !== -1) { label.removeClass(all).addClass('no-access'); }
    if (option.search(/read\ access/i)   !== -1) { label.removeClass(all).addClass('no-access'); }
    if (option.search(/read(.*)only/i)   !== -1) { label.removeClass(all).addClass('partial-access'); }
    if (option.search(/read(.*)write/i)  !== -1) { label.removeClass(all).addClass('full-access'); }
    if (option.search(/full\ access/i)   !== -1) { label.removeClass(all).addClass('full-access'); }
  }
};

$(document).ready(Permissions.init);
