var Notifications = {
  
  init: function(){
    $('.notifications .action input[type="checkbox"]').each(function(i, el){
      var rows = $(el).parents('.notifications').find('tbody tr:gt(0)');
      
      Notifications.watch($(el), rows.find('input:eq(' + i + ')'));
      
      if (rows.find('input:eq(' + i + '):not(:checked)').length == 0) $(el).prop('checked', true);
    });
    
    $('.notifications input[type="checkbox"]').each(function(){
      var checkbox = $(this).parent().end().addClass('subscription');
      
      checkbox.change(function(){
        Notifications.update(checkbox);
      });
      
      Notifications.update(checkbox);
    });
  },
  
  watch: function(el, checkboxes){
    el.click(function(){
      if (!confirm('Are you sure?')) {
        if (el.is(':checked'))
          el.prop('checked', false);
        else
          el.prop('checked', true);
        
        return;
      }

      checkboxes.each(function(){
        $(this).prop('checked', el.prop('checked'));
        
        Notifications.update($(this));
      });
    });
  },
  
  update: function(el){
    if (el.is(':checked'))
      el.parent().attr('class', 'subscription subscribed');
    else
      el.parent().attr('class', 'subscription unsubscribed');
  },

}

$(document).ready(Notifications.init);
