import CookieMinJS from './vendor/js.cookie.min';

var AciModal = {
  init: function () {
    AciModal.openModal();
    AciModal.openBtnModal();
    AciModal.closeModal();
    AciModal.submitModal();
    AciModal.dontAskAgain();
  },

  openModal: function(){
    var $modal_button = $('#aci-modal, #aci-modal-btn');
    var $modal = $('#aci-modal');
    var $button = $('#aci-modal-btn');
    var $cookie = Cookies.get('aci_state');
    var $survey = $('#survey');

    if($survey.length || $cookie === 'dismissed'){
      $modal.addClass('hide').removeClass("show");
      $button.addClass('show').removeClass("hide");
    }
    else if($cookie !== 'never') {
      $modal_button.addClass('show').removeClass("hide");
    }
    else {
      $modal_button.addClass("hide").removeClass('show');
    }
  },

  openBtnModal: function(){
    var $button = $('#aci-modal-btn');
    var $modal = $('#aci-modal');

    $button.on('click', function() {
      $modal.addClass('show').removeClass("hide");
    });
  },

  closeModal: function(){
    var $modal = $('#aci-modal');

    $(document).on('click keypress', function(e) {
      if ($modal.hasClass('show') && ($(e.target).is($modal) || $(e.target).is($modal.find('.close')) || e.keyCode === 27)) {
        $modal.addClass('hide').removeClass("show");
        Cookies.set('aci_state', 'dismissed');
      }
    });
  },

  dontAskAgain: function(){
    var $modal_button = $('#aci-modal, #aci-modal-btn');
    var $link = $('.aci-link');

    $link.on('click', function (){
      $modal_button.addClass('hide').removeClass("show");
      Cookies.set('aci_state', 'never', { expires: 365 });
    })
  },

  showSuccessModal: function(){
    var $response = $('#aci-success-modal');

    $response.addClass('show').removeClass("hide");
    setTimeout( function() { $response.addClass('hide').removeClass("show"); }, 3000);
  },

  showErrorModal: function(){
    var $response = $('#aci-error-modal');

    $response.addClass('show').removeClass("hide");
    setTimeout( function() { $response.addClass('hide').removeClass("show"); }, 3000);
  },

  submitModal: function(){
    $('#aci-form').on('submit', function() {
      var $this = $(this);
      var $modal_button = $('#aci-modal, #aci-modal-btn');

      $.ajax({
        type: "POST",
        url: this.action,
        data: $this.serialize(),

        start: function(){
          $this.find("input:not(:disabled), select:not(:disabled)").prop("disabled", true);
        },
        success: function() {
          $modal_button.addClass('hide').removeClass("show");
          Cookies.remove('aci_state');
          AciModal.showSuccessModal();
        },
        error: function(){
          $this.find("input:not(:disabled), select:not(:disabled)").prop("disabled", false);
          AciModal.showErrorModal();
        }
      });
    });
  }
};

$(document).ready(AciModal.init);
