jQuery.fn.timeAgoInWords = function () {

  if (this.length === 0) { return; }

  function parseDate (from) {
    var date = new Date();

    return date.setTime(Date.parse(from));
  }

  function distanceOfTimeInWords(to, from, output){
    var seconds = ((to - from) / 1000),
        minutes = Math.floor(seconds / 60);

    if (output === 'long') {
      if (minutes <= 0)       { return 'less than a minute ago'; }
      if (minutes === 1)      { return 'a minute ago'; }
      if (minutes < 45)       { return minutes + ' minutes ago'; }
      if (minutes < 90)       { return 'about 1 hour ago'; }
      if (minutes < 1440)     { return 'about ' + Math.floor(minutes / 60) + ' hours ago'; }
      if (minutes < 2880)     { return '1 day ago'; }
      if (minutes < 43200)    { return Math.floor(minutes / 1440) + ' days ago'; }
      if (minutes < 86400)    { return 'about 1 month ago'; }
      if (minutes < 525960)   { return Math.floor(minutes / 43200) + ' months ago'; }
      if (minutes < 1051199)  { return 'about 1 year ago'; }
    }
    if (output === 'short') {
      if (minutes <= 1)       { return 'just now'; }
      if (minutes < 45)       { return minutes + ' min ago'; }
      if (minutes < 90)       { return '~1 hour ago'; }
      if (minutes < 1440)     { return '~ ' + Math.floor(minutes / 60) + ' hours ago'; }
      if (minutes < 2880)     { return '1 day ago'; }
      if (minutes < 43200)    { return Math.floor(minutes / 1440) + ' days ago'; }
      if (minutes < 86400)    { return '~1 month ago'; }
      if (minutes < 525960)   { return Math.floor(minutes / 43200) + ' months ago'; }
      if (minutes < 1051199)  { return '~1 year ago'; }
    }

    return 'over ' + Math.floor(minutes / 525960) + ' years ago';
  }

  $(this).each(function () {
    var date = $(this).text();
    var timezone = parseDate($('#current-user-timezone').attr('datetime')) || new Date();

    $(this).attr('title', date);

    if ($(this).data('timeFormat') === 'short') {
      $(this).text(distanceOfTimeInWords(timezone, parseDate(date), 'short'));
    } else {
      $(this).text(distanceOfTimeInWords(timezone, parseDate(date), 'long'));
    }
  });
};