import { Dropdown } from './dropdown';

export var Header = {

  init: function () {

    var searchField       = $('.search-main .search-field'),
        searchResetButton = $('.search-main .search-reset'),
        filterLink        = $('.a-advanced-search'),
        definedFilterLink = $('.a-advanced-filter');

    Header.limitLongTitles();

    searchField
      .data('placeholder', searchField.attr('placeholder'))
      .keyup(function () {
        if ($(this).val() !== '') {
          searchResetButton.showMe();
        }
        if ($(this).val() === '' && filterLink.is(':visible')) {
          searchResetButton.hideMe();
        }
      });

    searchResetButton.on('click touchend', function (event) {
      event.preventDefault();
      Header.resetSearch(searchField, searchResetButton, filterLink, definedFilterLink);
    });

  },

  limitLongTitles: function () {
    if ($('.header-main--limited').length) {

      var navWidth = $('.mainnav').outerWidth() + 'px';

      $('.mainnav').css('margin-right', '-' + navWidth);
      $('.header-main').css('padding-right', navWidth).animate({opacity: 1}, 75);
    }
  },

  filterByUser: function (selectedUser) {

    var searchField       = $('.search-main .search-field'),
        searchResetButton = $('.search-main .search-reset'),
        filterLink        = $('.a-advanced-search'),
        definedFilterLink = $('.a-advanced-filter'),
        fullname          = selectedUser.find('u').text();

    $('#search-by-user').val(selectedUser.data('id'));


    // Close dropdown

    Dropdown.hide(selectedUser.parents('.dropdown'));


    // Replace settings icon with a photo

    filterLink.hideMe();
    definedFilterLink
      .showMe()
      .find('img').remove().end()
      .prepend(selectedUser.find('img').clone());


    // Update field placeholder and set focus

    searchField
      .attr('placeholder', 'Search by ' + fullname)
      .focus();


    // Show reset

    searchResetButton.showMe();

  },

  resetSearch: function (searchField, searchResetButton, filterLink, definedFilterLink) {

    $('#search-by-user').val('');


    // Replace photo with settings icon

    filterLink.showMe();
    definedFilterLink
      .hideMe()
      .find('img').remove();


    // Reset field value and revert placeholder

    searchField
      .attr('placeholder', searchField.data('placeholder'))
      .val('')
      .focus();


    // Hide reset

    searchResetButton.hideMe();
  }


};

$(document).ready(Header.init);
