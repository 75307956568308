$(function(){

  if ($('#invoice-preview').length == 0) return;
  
  $('textarea').keyup(function(){
    $('#invoice-preview pre').remove();
    $('<pre></pre>').text($(this).val()).appendTo($('#invoice-preview'));
  });

});
