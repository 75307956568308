export var Help = {
  
  init: function(){
    $('#help').data('current', false);
    
    if ($('#help').length == 0) {
      $('<div id="help"><div class="wrapper"><a href="#" class="close-help" style="display: none;">Close</a><div class="scroll-area"><div class="wrapper"></div></div></div></div>').hide().appendTo($('body'))
    }
    
    $(document).on('click', 'a[rel=help]', function(event){
      event.preventDefault();
      
      if ($(this).hasClass('maximize')) $('#help').css('width', 450)
      
      Help.load(this);
    });
    
    $('#help .close-help').click(function(event){
      event.preventDefault();
      
      Help.hide();
    });
  },
  
  load: function(el){
    if ($('#help').data('current') == el) {
      Help.hide();
      
      return;
    } else {
      Help.show();
      Help.refresh();
    }
    
    $('#help').data('current', el);

    $('#help .scroll-area .wrapper').html('').load($(el).attr('href'), function(){
      $('#help').removeClass('loading');

      Help.reset();
    });
  },
  
  show: function(){
    $('#help').addClass('loading').animate({
      'right': 0,
      visible: 'show'
    }, 250, function(){
      $('#help .close-help').show();
    });
  },
  
  hide: function(){
    $('#help .close-help').hide();
    
    $('#help').animate({
      'right': - ($('#help').width() + 40),
      'width': 300,
      visible: 'hide'
    }, 250).data('current', false);
  },
  
  maximize: function(callback){
    $('#help').animate({
      'width': 450
    }, 250, function(){
      Help.reset();
      Help.refresh();
      
      callback();
    });
  },
  
  minimize: function(callback){
    $('#help').animate({
      'width': 300
    }, 250, function(){
      Help.reset();
      Help.refresh();
      
      callback();
    });
  },
  
  refresh: function(){
    $('#help .scroll-area').css('height', $(window).height() - 20);
  },
  
  reset: function(){
    $('#help .scroll-area').jScrollPane();
  },
  
  resize: function(){
    $('#help .scroll-area').css('height', ($(window).height() - 20) + 'px');
    
    if ($('#help').isHidden()) return;
    
    if ($('#help .scroll-area').data('jsp') == undefined) return;

    var api = $('#help .scroll-area').data('jsp').reinitialise();
  }
  
}

$(document).ready(Help.init);
$(window).resize(Help.resize);
