var Integration = {

  init: function () {

    Integration.shopify();
  },

  shopify: function () {

    function reloadThemes(el) {

      var apiKey   = $('#release_server_login').val(),
        password = $('#release_server_password').val(),
        storeUrl = $('#release_server_bucket_name').val(),
        oauth    = $('#release_server_oauth_token').val(),
        serverID = $('#release_server_id').val(),
        errorMsg = $('#shopify-theme-error'),
        themes   = $('#shopify-theme'),
        loader   = themes.siblings('.icon-loading');

      // If value changed...
      if (el.data('prev-value') !== el.val()) {

        // Avoid rechecking if nothing was changed
        el.data('prev-value', el.val());

        // ...and all required fields have values
        if (apiKey !== '' && password !== '' && storeUrl !== '') {

          var uri = el.parents('fieldset').data('check-shopify-uri'),
            data = {
              api_key: apiKey,
              password: password,
              store_url: storeUrl,
              oauth_token: oauth,
              release_server_id: serverID
            };

          // Disable theme and show loader
          themes.prop('disabled', true);
          loader.showMe();

          $.get(uri, data)
            .always(function () {
              loader.hideMe();
              themes.prop('disabled', false);
            })
            .done(function (data) {
              errorMsg.hideMe();

              // Replace theme select content, then enable
              themes
                .html(data)
                .find('option:first').prop('selected', true);
            })
            .fail(function () {
              errorMsg.showMe();
            });
        }
      }
    }

    // Toggle creation of a new theme

    $('.js-shopify-theme').on('change', function () {
      if ($(this).val() === 'create_new') {
        $('#new-theme input, #new-theme select').prop('disabled', false);
        $('#new-theme').showMe().find('input:visible:first').focus();
      } else {
        $('#new-theme input, #new-theme select').prop('disabled', true);
        $('#new-theme').hideMe();
      }
    });

    // Load new themes when account changed

    function refreshFn() {
      var el = $(this);
      window.setTimeout(function () { reloadThemes(el); }, 100);
    }

    $('.js-shopify-auth-uri').on('blur', refreshFn);
    $('.js-shopify-auth-change').on('keyup paste', refreshFn);

    $('#shopify-theme').on('mousedown', function () {
      // if theme list was not preloaded properly for any reason, try to recover
      if ($('#shopify-theme').children().length < 2) {
        reloadThemes($('.js-shopify-auth-change'));
      }
    });

    $('.js-shopify-use-url').on('click', function (event) {
      var domain = '';
      if ($('#shopify-url').val() && $('#shopify-url').val() !== '') {
        domain = $('#shopify-url').val();
      } else {
        domain = $('#release_server_bucket_name').val();
      }

      if ($.trim(domain) === '') {
        window.alert('Please enter a valid store URL and try again.');
        return;
      }

      var shopify_url = domain + '.myshopify.com';

      var new_url = $('.js-shopify-use-url').data('base-href').replace(/https?:\/\/.*?\//, 'https://' + shopify_url + '/');

      $('.js-shopify-use-url').attr('href', new_url);
    });
  }
};

$(document).ready(Integration.init);
