var Survey = {
  init: function(){
    Survey.open();
    Survey.close();
    Survey.submitForm();
    Survey.removeMessageBubble();
  },

  open: function(){
    var $survey = $('#survey')
    if($survey.length !== 0) {
      $('#survey').addClass('show').removeClass('hide');
    }
  },

  close: function(){

    $('.survey-close, .survey-skip-button').on('click', function() {
      var $skip_survey_checked = $('#survey_answer_choice_skipped').is(':checked');
      var $form = $('#new_survey_answer');
      if($skip_survey_checked){
        $form.submit();
      }
      $('#survey').addClass('hide').removeClass('show');
    });
  },

  removeMessageBubble: function(){
    $(".survey-choice-radio-btn").on("change", function() {
      if($(this).is(':checked')){
        $('.survey-error-holder').addClass('hide').removeClass('show');
      }
    })
  },

  submitForm: function(){
    $('#new_survey_answer').on('submit', function(e){
      e.preventDefault();
      var $this = $(this);
      var $data = $this.serializeArray()
      if ($('.survey-choice-radio-btn').is(':checked')){
        $data = $data.filter(function(ele){
         return ele['value'] !== "skipped"
        })
      }
      var $error_holder = $('.survey-error-holder');
      if(!$('.survey-choice-radio-btn, .survey-choice-check-box').is(':checked')){
        $error_holder.html($("<span/>").addClass("survey-message").text('Please select an answer.'));
        $error_holder.addClass('show').removeClass('hide');
      } else {
        $error_holder.addClass('hide').removeClass('show');
        $.ajax({
          type: "POST",
          url: this.action,
          data: $data,
          dataType: 'json',
          success: function(){
            $('#survey').addClass('hide').removeClass('show');
          },
          error: function(){
            $error_holder.addClass('show').removeClass('hide');
            $error_holder.html($("<span/>").addClass("survey-message").text('Something went wrong. Please try again.'));
          }
        })
      }
    })
  }
};

$(document).ready(Survey.init);
