import jquery from 'jquery';
window.jQuery = jquery;
window.$ = jquery;

// Vendor plugins
require('../vendor/jquery_ujs');
require('../vendor/modernizr');
require('../vendor/jquery.hotkeys');
require('../vendor/jquery.mousewheel');
require('../vendor/jquery.jscrollpane');
require('../vendor/jquery.ba-bbq');
require('../vendor/jquery.smooth-scroll');
require('../vendor/qs_score');
require('../vendor/jquery.autocomplete');
require('../vendor/highlight.pack');

// Internal plugins
require('../jquery.time-ago');
require('../jquery.timezone');
require('../jquery.hud');
require('../jquery.mask');
require('../jquery.tooltips');
require('../jquery.urlcheck');
require('../strong-password');

// Modules
require('../header');
require('../case');
require('../curtain');
require('../create-server');
require('../dashboard');
require('../incidents');
require('../invoice');
require('../integration');
require('../manual-deployment');
require('../notifications');
require('../permissions');
require('../select-revision');
require('../transfer-log');
require('../users');
require('../security');
require('../upgrade');
require('../connect-repository');
require('../cancel-account');
require('../config-files');
require('../api-tokens');
require('../foldable-sets');
require('../servers-status');
require('../aci_modal');
require('../assistant/assistant');
require('../remote-path-preview');
require('../survey');
require('../sidebar-navigation');

// Branches
require('../vendor/websockets/web_socket');
