$(function(){

  $('#users-filter select').change(function(){
    var type  = $(this).val();
    var users = $('#users-list a, #users-list div');
    
    if (type == 'all') {
      $(users).removeClass('fade');
    } else {  
      switch(type){
        case 'users':
          $(users).removeClass('fade');
          $('#users-list .role').parent().addClass('fade');
        break;
        case 'admins':
          $(users).addClass('fade')
          $('#users-list').find('.role[class~="user-type-admin"], .role[class~="user-type-owner"]').parent().removeClass('fade');
        break;
        case 'invited':
          $(users).addClass('fade')
          $('#users-list .role[class~="user-type-invited"]').parent().removeClass('fade');
        break;
      }
    }
  });

});