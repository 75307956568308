var Upgrade = {

  init: function () {
    Upgrade.showPlanFromURI();
    Upgrade.toggleCreditCardForm($('.js-plan:visible'));

    $('.plan-item-wrap').click(function (event) {
      event.preventDefault();
      Upgrade.switchPlans($(this));
    });

    if ('onhashchange' in window) {
      window.onhashchange = function () {
        Upgrade.showPlanFromURI();
      }
    }
  },

  switchPlans: function (plan) {

    var planElement = $(plan.prop('hash')),
        planName    = plan.find('.plan-item-title').text(),
        planPrice   = plan.find('.plan-item-price b').text(),
        submitBtn   = $('.js-enter-credit-card input[type="submit"]'),
        currentSubmitText;


    // Do nothing if this is currently selected plan

    if (planElement.is(':visible')) {
      return;
    }


    // Switch plan

    $('.js-plan').hideMe();
    planElement.showMe();
    $.smoothScroll();


    // Highlight plan description

    $('.plan-showcase')
      .addClass('bloom')
      .on(
        'webkitTransitionEnd oTransitionEnd transitionend',
        function () {
          $(this).removeClass('bloom');
        }
      );


    // Change data in credit card form

        if(planPrice == 'Free') {
            $('.upgrade-form-summary').hideMe();
        } else {
            $('.js-current-plan-price').text(planPrice);
            $('.upgrade-form-summary').showMe();
        }

    $('.js-current-plan-name').text(planName);
    $('.js-enter-credit-card').attr('action', '/plans/' + planName + '/add_payment_method');


    // Change submit button

    if (submitBtn.length) {
      currentSubmitText = submitBtn.val();
      submitBtn.val(currentSubmitText.replace(/to [\w\s]+$/g, 'to ' + planName));
    }


    // Hide or show credit card form

    Upgrade.toggleCreditCardForm(planElement);


    // Change currently selected plan

    $('.plan-item--current').removeClass('plan-item--current');
    $(plan).parents('.plan-item').addClass('plan-item--current');

  },

  toggleCreditCardForm: function (current) {

    if ($(current).find('.upgrade-form').length) {
      $('.js-enter-credit-card').hideMe();
    } else {
      $('.js-enter-credit-card').showMe();
    }

  },

  showPlanFromURI: function () {

    var hash = window.location.hash,
        plan = $('a[href="' + hash + '"]');

    if (hash.length && $(plan).length) {
      Upgrade.switchPlans($(plan));
    }
  }

};

$(document).ready(Upgrade.init);
