$(function(){

  function refresh(){
    $('div[id^="reason-"]').hideMe().find(':input').prop('disabled', true);
    $('#reason-' + $('#cancel-reasons select').val()).showMe().find(':input').prop('disabled', false);;
  }

  $('#cancel-reasons select').change(function(){
    $('.form-row').find('textarea, input[type="text"]').val('');
    
    refresh();
  });
  
  refresh();

});