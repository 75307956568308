jQuery.fn.hud = function(options){
  
  if (this.length == 0) return;

  var settings = jQuery.extend({
    type: 'remote', // remote, local or notice
    size: 'large', // or small
    kind: 'revision',
    name: '',
    container: '',
    offset: [0, 0],
    onShow: function(){},
    onHide: function(){}
  }, options);
  
  if ($('body').width() <= 480) settings.size = 'small'; /* Needs better mobile check... */
  
  $(this).each(function(){
    var self       = $(this);
    var hud       = $('<div class="hud size-' + settings.size + '" id="' + settings.name + '"><div class="wrapper loading">Loading&hellip;</div><b class="hud-arrow"></b></div>').hide().appendTo($('body'));
    var container;
    var loaded    = false;
    var timeout;
    
    self.data('hud', hud);
    
    hud.data({
      status: 'hidden',
      self: self,
      settings: settings,
      url: self.attr('href')
    });
    
    if (settings.container == '') container = $('#' + self.attr('rel')); else container = $(settings.container);
    
    if (container.length == 0) return;
    
    if (settings.type == 'notice') {
      window.setTimeout(function(){
        notice();
      }, 500);
      
      return;
    } else {
      init();
    }
    
    function init(){      
      self.off('click.toggleHUD').on('click.toggleHUD', function(event){
        event.preventDefault();

        if (hud.is(':hidden')) {
          event.stopPropagation();

          // Close all open HUDs
          $('.hud').each(function(){
            if ($(this).is(':hidden')) return;

            $(this).hide();
            closeMe(false);
          });

          show();

          $(document).on('click.closeHUD', function() {
            closeMe(true);
          });

          $('#preview iframe').contents().on('click.closeHUD', function() {
            closeMe(true);
          });

          hud.off('click.dontCloseHUD').on('click.dontCloseHUD', function (event) {
            event.stopPropagation();
          });

        } else {
          closeMe(true);
        }

      });
    }

    function closeMe(toHide){
      $(document).off('click.closeHUD');
      $('#preview iframe').contents().off('click.closeHUD');
      if (toHide) hide();
    }

    function startup(){
      if (settings.type == 'remote') {
        $.get(self.attr('href'), function(data){
          load(data);
        }, 'json');
      } else {
        if (jQuery.trim(container.html()) == '') {
          $.get(self.attr('href'), function(data){
            load(data);
            container.html(data);
          }, 'json');
        } else {
          load(container.html());
        }
      }
    }
    
    function notice(){
      timeout = window.setTimeout(function(){
        hide();
      }, 5000);
      
      hud.find('.wrapper').removeClass('loading').html(container.html()).end().mouseenter(function(){
        window.clearTimeout(timeout);
        
        hide();
      });

      show();
    }
    
    function load(data){
      hud.find('.wrapper').html(data).removeClass('loading');
      
      loaded = true;

      settings.onShow(hud);
    }
    
    function show(){
      refresh(hud);
      animate();
      
      hud.data('status', 'visible');
      
      if (!loaded)
        startup();
      else
        settings.onShow(hud);
    }

    function hide(){
      animate();
      
      hud.data('status', 'hidden');
    
      settings.onHide(hud);
    }
    
    function animate(){
      hud.animate({
        opacity: 'toggle'
      }, 100, 'linear');
    }
  });
  
  function refresh(hud){
    var self      = hud.data('self');
    var settings  = hud.data('settings');
    var type      = 'above';
    var offset    = 0;
    var vOffset   = settings.size === 'large' ? 320 : 270;

    hud.attr('class', '').addClass('hud size-' + settings.size);
    
    // 2DO: Cleanup...
    
    if (self.offset().left + hud.width() > $(window).width()) {
      hud.addClass('align-right').css('right', ($(window).width() - self.offset().left - self.outerWidth()) + settings.offset[0]);
    } else if (self.offset().left - (hud.width() / 2) <= 0) {
      hud.addClass('align-left').css('left', self.offset().left - 10);
    } else {
      hud.addClass('align-middle').css('left', (self.offset().left - hud.width() / 2) + self.outerWidth() / 2);
    }

    if (self.offset().top < vOffset) {
      hud.addClass('below-element').css('top', (self.offset().top + self.outerHeight() + 4) + settings.offset[1]);
    } else {
      hud.addClass('above-element').css('bottom', ($(window).height() - self.offset().top + 4) + settings.offset[1]);
    }
  }
  
  $(window).resize(function(){
    $('.hud:visible').each(function(){
      refresh($(this))
    });
  });

  return this;
};
