import { Help } from './help';

var Case = {
  
  init: function(){
    $(document)
      .on('click.toggleTicket', '#help .support-options a:first, #submit-case .form-submit a', function(event){
        event.preventDefault();

        Case.toggle();
      })
      .on('submit.sendTicket', '#submit-case form', function(event){
        event.preventDefault();

        var form = $(this);

        if (form.find('textarea').val() == '') {
          form.find('.form-error').showMe();

          return;
        } else
          form.find('.form-error').hideMe();
        
        Help.minimize(function(){
          $('#help').addClass('loading').find('.scroll-area .wrapper').html('');
          
          $.post(form.attr('action'), form.serialize(), function(data){
            $('#help').removeClass('loading').find('.scroll-area .wrapper').html(data);
          });
        });
      });
  },
  
  toggle: function(){
    if ($('#submit-case').is(':visible')) {
      $('#submit-case').fadeOut(200);

      Help.minimize(function(){
        $('#help-overview').fadeIn(200);
      });
    } else {
      $('#help-overview').fadeOut(200);

      Help.maximize(function(){
        $('#submit-case').fadeIn(200);
      });
    }
  }
  
}

$(document).ready(Case.init);
